import React, { FC, useCallback, useEffect, useState } from 'react'
import { Grid, Paper, Typography } from '@mui/material'
import {
  DataGridPremium,
  useGridApiRef,
  gridFilterModelSelector,
  gridFilteredTopLevelRowCountSelector,
  GridPaginationModel,
} from '@mui/x-data-grid-premium'
import { UnstableSensor } from 'src/api'
import { useUnstableSensorsListRequest } from 'src/hooks/api'
import { useQueryParams } from 'src/hooks/ui'
import { SearchParamsKeys } from 'src/enums'
import { UnstableSensorsColumns } from 'src/components/columns/sensor'
import { defaultRowsValuePerPage } from 'src/config'

const unstableSensorColumns = [
  UnstableSensorsColumns.DateTime,
  UnstableSensorsColumns.IMEI,
  UnstableSensorsColumns.NumberMissedUplinks,
]
const defaultPerPage = 50
const defaultPage = 1
const defaultRowsPerPageOptions = defaultRowsValuePerPage
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}

export const UnstableSensorsTable: FC = () => {
  const apiRef = useGridApiRef()
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)
  const { data, isInitialLoading } = useUnstableSensorsListRequest({
    params: queryParams,
  })
  const numberOfUnstableSensors = useCallback(() => data?.count || 0, [data])()
  const unstableSensorList = useCallback(() => data?.rows || [], [data])()
  const [rowCountState, setRowCountState] = useState(numberOfUnstableSensors)

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      numberOfUnstableSensors !== undefined ? numberOfUnstableSensors : prevRowCountState
    )
  }, [numberOfUnstableSensors, setRowCountState])

  const handleChangePaginationModel = async (pagination: GridPaginationModel): Promise<void> => {
    const { page, pageSize } = pagination
    const newPage = page + 1
    if (setQueryParams) {
      setQueryParams({
        [SearchParamsKeys.Page]: `${newPage}`,
        [SearchParamsKeys.RowsPerPage]: `${pageSize}`,
      }, [
        SearchParamsKeys.Page,
        SearchParamsKeys.RowsPerPage,
      ])
    }
  }

  const handleChangeState = async () => {
    const filterModel = gridFilterModelSelector(apiRef.current.state)
    if (filterModel.items.length > 0 && filterModel.items[0].value) {
      const visibleRowCount = gridFilteredTopLevelRowCountSelector(apiRef.current.state)
      if (visibleRowCount) setRowCountState(visibleRowCount)
    }
    else setRowCountState(numberOfUnstableSensors)
  }

  const page = queryParams["page"] ? parseInt(queryParams["page"], 10) - 1 : defaultPage - 1
  const pageSize = queryParams["rowsPerPage"]
    ? parseInt(queryParams["rowsPerPage"], 10)
    : defaultPerPage

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <Typography variant='h6'>Unstable sensors</Typography>
      </Grid>
      <Grid container item>
        <Paper sx={{ flexGrow: 1, minHeight: '200px', maxHeight: '500px' }}>
          <DataGridPremium<UnstableSensor>
            apiRef={apiRef}
            pagination
            disableRowSelectionOnClick
            rows={unstableSensorList}
            columns={unstableSensorColumns}
            rowCount={rowCountState}
            loading={isInitialLoading}
            paginationModel={{
              page: page as number,
              pageSize: pageSize as number
            }}
            rowThreshold={2}
            columnThreshold={2}
            paginationMode="server"
            pageSizeOptions={defaultRowsPerPageOptions}
            onPaginationModelChange={handleChangePaginationModel}
            onStateChange={handleChangeState}
            localeText={{
              columnMenuSortAsc: "Sort A-Z",
              columnMenuSortDesc: "Sort Z-A",
            }}
            sx={(theme) => ({
              border: "none",
              "& .MuiDataGrid-columnHeaderTitleContainerContent": {
                paddingLeft: "5px",
                overflow: "visible",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: 1,
                fontSize: "13px",
                fontWeight: 400,
                color: "rgba(0, 0, 0, 0.6)",
                overflow: "visible",
              },
            })}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}
