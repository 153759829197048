import React from 'react'

export const LogoHorizontalWhite = () => (
  <svg
    viewBox="0 0 194 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_902_22759)">
      <path
        d="M40.3963 20.4695V8.36151C40.3963 8.09003 40.5049 7.87285 40.6678 7.70996C40.8307 7.54707 41.0479 7.43848 41.3193 7.43848H52.5043C52.7758 7.43848 52.993 7.54707 53.1559 7.70996C53.3188 7.87285 53.4274 8.09003 53.4274 8.36151V9.28454C53.4274 9.55603 53.3188 9.77321 53.1559 9.9361C52.993 10.099 52.7758 10.2076 52.5043 10.2076H44.0884V13.6282H50.984C51.2555 13.6282 51.4727 13.7368 51.6356 13.8997C51.7985 14.0626 51.9071 14.2798 51.9071 14.5513V15.4743C51.9071 15.7458 51.7985 15.963 51.6356 16.1259C51.4727 16.2887 51.2555 16.3973 50.984 16.3973H44.0884V20.4695C44.0884 20.741 43.9798 20.9582 43.817 21.1211C43.6541 21.284 43.4369 21.3926 43.1654 21.3926H41.3193C41.0479 21.3926 40.8307 21.284 40.6678 21.1211C40.5049 20.9582 40.3963 20.741 40.3963 20.4695Z"
        fill="white"
      />
      <path
        d="M54.459 20.524V8.41595C54.459 8.14447 54.5676 7.92729 54.7305 7.7644C54.8934 7.60151 55.1106 7.49292 55.382 7.49292H57.2281C57.4996 7.49292 57.7168 7.60151 57.8797 7.7644C58.0425 7.92729 58.1511 8.14447 58.1511 8.41595V18.6236H66.6213C66.8928 18.6236 67.11 18.7322 67.2729 18.8951C67.4358 19.058 67.5444 19.2752 67.5444 19.5467V20.4697C67.5444 20.7412 67.4358 20.9584 67.2729 21.1212C67.11 21.2841 66.8928 21.3927 66.6213 21.3927H55.382C55.1106 21.3927 54.8934 21.2841 54.7305 21.1212C54.5133 20.9584 54.459 20.7412 54.459 20.524Z"
        fill="white"
      />
      <path
        d="M80.8469 19.9809C79.5438 21.1211 77.5891 21.7183 75.0372 21.7183C72.4853 21.7183 70.5306 21.1211 69.2275 19.9809C67.9244 18.8407 67.2186 16.9946 67.2186 14.4427C67.2186 11.9451 67.8701 10.099 69.2275 8.90447C70.5849 7.70995 72.4853 7.16699 75.0372 7.16699C77.5891 7.16699 79.5438 7.76425 80.8469 8.90447C82.15 10.0447 82.8559 11.8908 82.8559 14.4427C82.8559 16.9403 82.2043 18.7864 80.8469 19.9809ZM78.1864 10.9677C77.5348 10.2076 76.4489 9.8275 75.0372 9.8275C73.5712 9.8275 72.5396 10.2076 71.888 10.9677C71.2365 11.7279 70.9107 12.9224 70.9107 14.4427C70.9107 15.963 71.2365 17.1575 71.888 17.9176C72.5396 18.6778 73.6255 19.0578 75.0372 19.0578C76.5032 19.0578 77.5348 18.6778 78.1864 17.9176C78.838 17.1575 79.1637 15.963 79.1637 14.4427C79.1637 12.9224 78.8922 11.7822 78.1864 10.9677Z"
        fill="white"
      />
      <path
        d="M97.1357 19.9809C95.8326 21.1211 93.878 21.7183 91.3261 21.7183C88.7741 21.7183 86.8195 21.1211 85.5164 19.9809C84.2133 18.8407 83.5074 16.9946 83.5074 14.4427C83.5074 11.9451 84.159 10.099 85.5164 8.90447C86.8195 7.76425 88.7741 7.16699 91.3261 7.16699C93.878 7.16699 95.8326 7.76425 97.1357 8.90447C98.4388 10.0447 99.1447 11.8908 99.1447 14.4427C99.1447 16.9403 98.4388 18.7864 97.1357 19.9809ZM94.4752 10.9677C93.8237 10.2076 92.7378 9.8275 91.3261 9.8275C89.8601 9.8275 88.8284 10.2076 88.1769 10.9677C87.5253 11.7279 87.1996 12.9224 87.1996 14.4427C87.1996 15.963 87.5253 17.1575 88.1769 17.9176C88.8284 18.6778 89.9144 19.0578 91.3261 19.0578C92.7921 19.0578 93.8237 18.6778 94.4752 17.9176C95.1268 17.1575 95.4526 15.963 95.4526 14.4427C95.4526 12.9224 95.1268 11.7822 94.4752 10.9677Z"
        fill="white"
      />
      <path
        d="M114.673 21.0127C114.673 21.2841 114.511 21.447 114.185 21.447H111.85C111.579 21.447 111.307 21.3384 111.036 21.1755C110.764 21.0127 110.493 20.7955 110.384 20.524L108.755 17.9178C108.049 16.8319 107.235 16.3432 106.312 16.3432H103.923V20.524C103.923 20.7955 103.814 21.0127 103.651 21.1755C103.488 21.3384 103.271 21.447 103 21.447H101.154C100.882 21.447 100.665 21.3384 100.502 21.1755C100.339 21.0127 100.231 20.7955 100.231 20.524V8.41595C100.231 8.14447 100.339 7.92729 100.502 7.7644C100.665 7.60151 100.882 7.49292 101.154 7.49292H109.135C110.601 7.49292 111.85 7.87299 112.773 8.68743C113.696 9.50188 114.185 10.5335 114.185 11.8909C114.185 13.7913 112.99 14.9315 110.547 15.4202C110.927 15.583 111.307 15.8002 111.633 16.1803C111.959 16.5061 112.339 17.049 112.773 17.7006L114.565 20.524C114.619 20.6869 114.673 20.8498 114.673 21.0127ZM103.868 13.5741H107.995C108.647 13.5741 109.244 13.4655 109.732 13.194C110.221 12.9225 110.493 12.4882 110.493 11.9452C110.493 11.3479 110.221 10.9136 109.732 10.6964C109.244 10.4249 108.647 10.3163 107.995 10.3163H103.868V13.5741Z"
        fill="white"
      />
      <path
        d="M116.791 19.7635C115.596 18.6233 114.999 16.8316 114.999 14.4425C114.999 12.0535 115.596 10.2617 116.791 9.12151C117.985 7.98129 119.777 7.38403 122.221 7.38403H124.392C125.858 7.38403 127.161 7.76411 128.302 8.52425C129.442 9.2844 129.985 10.316 129.985 11.6734C129.985 12.0535 129.876 12.325 129.659 12.4879C129.442 12.6508 129.225 12.7593 129.008 12.7593H127.867C127.27 12.7593 126.836 12.3793 126.456 11.6734C125.967 10.6418 125.207 10.1531 124.067 10.1531H122.329C120.917 10.1531 119.94 10.4789 119.451 11.0762C118.963 11.6734 118.691 12.8136 118.691 14.4425C118.691 16.0714 118.963 17.2116 119.451 17.8089C119.94 18.4061 120.917 18.7319 122.329 18.7319H124.284C125.37 18.7319 126.184 18.2433 126.673 17.2116C126.999 16.5058 127.487 16.1257 128.085 16.1257H129.225C129.551 16.1257 129.768 16.2343 129.931 16.4515C130.093 16.6687 130.202 16.9401 130.202 17.2116C130.202 18.5147 129.659 19.6007 128.519 20.3608C127.379 21.1209 126.076 21.501 124.61 21.501H122.221C119.777 21.501 117.985 20.9038 116.791 19.7635Z"
        fill="white"
      />
      <path
        d="M131.125 20.524V8.41595C131.125 8.14447 131.234 7.92729 131.397 7.7644C131.559 7.60151 131.777 7.49292 132.048 7.49292H133.894C134.166 7.49292 134.383 7.60151 134.546 7.7644C134.709 7.92729 134.817 8.14447 134.817 8.41595V18.6236H143.287C143.559 18.6236 143.776 18.7322 143.939 18.8951C144.102 19.058 144.21 19.2752 144.21 19.5467V20.4697C144.21 20.7412 144.102 20.9584 143.939 21.1212C143.776 21.2841 143.559 21.3927 143.287 21.3927H132.102C131.831 21.3927 131.614 21.2841 131.451 21.1212C131.234 20.9584 131.125 20.7412 131.125 20.524Z"
        fill="white"
      />
      <path
        d="M157.567 19.9809C156.264 21.1211 154.31 21.7183 151.758 21.7183C149.206 21.7183 147.251 21.1211 145.948 19.9809C144.645 18.8407 143.939 16.9946 143.939 14.4427C143.939 11.9451 144.591 10.099 145.948 8.90447C147.251 7.76425 149.206 7.16699 151.758 7.16699C154.31 7.16699 156.264 7.76425 157.567 8.90447C158.87 10.0447 159.576 11.8908 159.576 14.4427C159.576 16.9403 158.87 18.7864 157.567 19.9809ZM154.907 10.9677C154.255 10.2076 153.169 9.8275 151.758 9.8275C150.292 9.8275 149.26 10.2076 148.608 10.9677C147.957 11.7279 147.631 12.9224 147.631 14.4427C147.631 15.963 147.957 17.1575 148.608 17.9176C149.26 18.6778 150.346 19.0578 151.758 19.0578C153.224 19.0578 154.255 18.6778 154.907 17.9176C155.558 17.1575 155.884 15.963 155.884 14.4427C155.884 12.9224 155.558 11.7822 154.907 10.9677Z"
        fill="white"
      />
      <path
        d="M160.662 15.9088V8.41595C160.662 8.14447 160.771 7.92729 160.934 7.7644C161.097 7.60151 161.314 7.49292 161.585 7.49292H163.377C163.648 7.49292 163.811 7.60151 164.029 7.7644C164.191 7.92729 164.3 8.14447 164.3 8.41595V15.9088C164.3 16.8861 164.517 17.592 164.952 18.0264C165.386 18.515 166.038 18.7322 166.906 18.7322H168.481C169.35 18.7322 170.001 18.515 170.435 18.0264C170.87 17.5377 171.087 16.8319 171.087 15.9088V8.41595C171.087 8.14447 171.196 7.92729 171.359 7.7644C171.521 7.60151 171.739 7.49292 172.01 7.49292H173.802C174.073 7.49292 174.291 7.60151 174.453 7.7644C174.616 7.92729 174.725 8.14447 174.725 8.41595V15.9088C174.725 17.7006 174.182 19.058 173.15 20.0353C172.119 21.0127 170.598 21.5013 168.589 21.5013H166.852C164.843 21.5013 163.323 21.0127 162.291 20.0353C161.259 19.058 160.662 17.7006 160.662 15.9088Z"
        fill="white"
      />
      <path
        d="M175.865 20.524V8.41595C175.865 8.14447 175.974 7.92729 176.137 7.7644C176.299 7.60151 176.517 7.49292 176.788 7.49292H182.218C185.041 7.49292 187.05 8.03588 188.353 9.1761C189.656 10.3163 190.254 12.0538 190.254 14.4428C190.254 16.8319 189.602 18.6236 188.353 19.7095C187.05 20.8498 185.041 21.3927 182.218 21.3927H176.788C176.517 21.3927 176.299 21.2841 176.137 21.1212C175.974 20.9584 175.865 20.7412 175.865 20.524ZM179.503 18.6779H182.109C183.847 18.6779 185.041 18.3521 185.638 17.7006C186.29 17.049 186.561 15.9631 186.561 14.4428C186.561 12.9225 186.236 11.8366 185.638 11.1851C184.987 10.5335 183.847 10.2077 182.109 10.2077H179.503V18.6779Z"
        fill="white"
      />
      <path
        d="M189.331 7.54721V7.33003C189.331 7.27573 189.385 7.22144 189.439 7.22144H191.177C191.231 7.22144 191.285 7.27573 191.285 7.33003V7.54721C191.285 7.60151 191.231 7.6558 191.177 7.6558H190.634V9.0675C190.634 9.1218 190.634 9.1218 190.579 9.1218L190.525 9.1761H190.145C190.091 9.1761 190.091 9.17609 190.091 9.1218L190.036 9.0675V7.6558H189.493C189.385 7.6558 189.331 7.6558 189.331 7.54721ZM191.448 9.0675V7.33003C191.448 7.27573 191.502 7.22144 191.557 7.22144H192.154C192.208 7.22144 192.263 7.27573 192.317 7.33003L192.751 8.36165L193.186 7.33003C193.24 7.22144 193.294 7.22144 193.348 7.22144H193.946C194 7.22144 194.054 7.27573 194.054 7.33003V9.0675C194.054 9.1218 194.054 9.1218 194 9.1218L193.946 9.1761H193.566C193.566 9.1761 193.511 9.17609 193.511 9.1218L193.457 9.0675V7.98158L193.023 9.0675C192.968 9.1761 192.914 9.1761 192.86 9.1761H192.697C192.643 9.1761 192.588 9.1218 192.534 9.0675L192.1 7.98158V9.0675C192.1 9.1218 192.1 9.1218 192.045 9.1218L191.991 9.1761H191.611C191.557 9.1761 191.557 9.17609 191.557 9.1218C191.448 9.1218 191.448 9.1218 191.448 9.0675Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1576 0.0544434C19.8181 0.0544434 22.2614 1.14037 23.9989 2.93214C25.1934 2.28058 26.5508 1.95481 28.0168 1.95481C32.7406 1.95481 36.5956 5.80983 36.5956 10.5336C36.5956 14.9316 33.2835 18.5694 28.9941 19.0581C28.7769 24.1076 24.5961 28.2341 19.438 28.2341C16.7775 28.2341 14.3342 27.1482 12.5967 25.3564C11.4022 26.008 10.0448 26.3338 8.57879 26.3338C3.85502 26.3338 0 22.4787 0 17.755C0 13.357 3.31206 9.71915 7.60146 9.23049C7.81864 4.12665 12.0537 0.0544434 17.1576 0.0544434Z"
        fill="white"
      />
      <path
        d="M28.0168 1.90036C26.6051 1.90036 25.2477 2.22614 23.9989 2.87769C22.2071 1.03163 19.7638 0 17.1576 0C14.6599 0 12.2709 0.97733 10.4791 2.71481C8.68738 4.45228 7.65575 6.73272 7.60145 9.23034C3.25777 9.66471 0 13.3568 0 17.7548C0 22.4786 3.85502 26.3879 8.63308 26.3879C10.0448 26.3879 11.4022 26.0621 12.651 25.4106C14.4428 27.2566 16.8861 28.2883 19.4923 28.2883C21.9899 28.2883 24.379 27.3109 26.1707 25.5735C27.9625 23.836 28.9941 21.5556 29.0484 19.0579C33.3921 18.5693 36.6499 14.8771 36.6499 10.4791C36.6499 5.75539 32.7948 1.90036 28.0168 1.90036ZM16.6689 15.2572C16.506 15.1486 16.2888 15.2029 16.1259 15.3115L12.8139 17.8091C12.7053 17.9177 12.5967 18.0263 12.5967 18.1892C12.5967 18.3521 12.651 18.515 12.8139 18.5693L16.1259 21.0669C16.2888 21.1755 16.4517 21.1755 16.6689 21.1212C16.8318 21.0126 16.9404 20.8497 16.9404 20.6868V19.0579H27.4195C27.1481 23.3473 23.6731 26.6594 19.438 26.6594C17.1033 26.6594 14.8771 25.6278 13.3568 23.836L12.9225 23.293L12.3252 23.6731C11.185 24.379 9.93619 24.759 8.57878 24.759C4.77806 24.759 1.62888 21.6099 1.62888 17.7548C1.62888 13.8998 4.77806 10.7506 8.63308 10.7506H18.6236V12.4881C18.6236 12.7053 18.7322 12.8682 18.895 12.9225C19.0579 13.0311 19.2751 12.9768 19.438 12.8682L22.7501 10.3706C22.8587 10.262 22.9673 10.1534 22.9673 9.99048C22.9673 9.8276 22.913 9.66471 22.7501 9.61041L19.3837 7.05849C19.2208 6.9499 19.0579 6.9499 18.8407 7.0042C18.6779 7.11279 18.5693 7.27568 18.5693 7.43857V9.12175H9.23034C9.44752 4.94095 12.9225 1.62888 17.1576 1.62888C19.4923 1.62888 21.7184 2.66051 23.2387 4.45228L23.6731 4.99524L24.2704 4.61517C25.4106 3.90932 26.6594 3.52925 28.0168 3.52925C31.8718 3.52925 35.021 6.67842 35.021 10.5334C35.021 14.3885 31.8718 17.5376 28.0168 17.5376H16.9404V15.7459C16.9404 15.5287 16.8318 15.3658 16.6689 15.2572Z"
        fill="#037BEB"
      />
    </g>
    <defs>
      <clipPath id="clip0_902_22759">
        <rect width="194" height="28.2883" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
