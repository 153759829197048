import { FC, PropsWithChildren, SyntheticEvent, useEffect, useState } from "react"
import Autocomplete from "@mui/material/Autocomplete"
import Grid from "@mui/material/Grid"
import Checkbox from "@mui/material/Checkbox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import TextField from "@mui/material/TextField"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import { EventType } from "src/api"
import { useAddPopupMessage, useQueryParams } from "src/hooks/ui"
import { SearchParamsKeys } from "src/enums"
import Button from "@mui/material/Button"
import { PropsInjector, Switch } from "src/components/ui"
import { useEventTypeListRequest } from "src/hooks/api"

export type EventListFiltersProps = {
  eventTypes?: EventType[]
  isLoadingEventTypes?: boolean
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const FiltersDataLoader: FC<PropsWithChildren> = ({ children }) => {
  const eventTypes = useEventTypeListRequest({ params: { limit: 100 } })
  const addMessage = useAddPopupMessage()

  const isLoading = eventTypes.isInitialLoading
  const isError = eventTypes.isError
  const error = eventTypes.error

  const props: EventListFiltersProps = {
    eventTypes: eventTypes.data?.rows || [],
    isLoadingEventTypes: isLoading,
  }

  if (isError) {
    addMessage({ text: error?.message, type: 'error' })
    return null
  }

  return (
    <PropsInjector props={props}>{children}</PropsInjector>
  )
}

const EventListFiltersContent: FC<EventListFiltersProps> = ({ eventTypes, isLoadingEventTypes }) => {
  const [queryParams, setQueryParams, removeQueryParams] = useQueryParams()
  const [selectedEventTypes, setSelectedEventTypes] = useState<EventType[]>(() => {
    const eventTypeQueryString = queryParams[SearchParamsKeys.EventTypes]
    if (eventTypeQueryString) {
      const ids = eventTypeQueryString.split(',').map((id) => parseInt(id, 10))
      const selectedTypes = (eventTypes || []).filter((type) => ids.includes(type.id))
      return selectedTypes
    }
    return []
  })
  const [hideServiceAccount, setHideServiceAccount] = useState<boolean>(() => {
    const hideServiceAccountQueryParam = queryParams[SearchParamsKeys.HideServiceAccount]
    if (hideServiceAccountQueryParam && hideServiceAccountQueryParam === 'true') {
      return true
    }
    return false
  })

  useEffect(() => {
    if (eventTypes && eventTypes.length > 0) {
      const eventTypeQueryString = queryParams[SearchParamsKeys.EventTypes]
      if (eventTypeQueryString) {
        const ids = eventTypeQueryString.split(',').map((id) => parseInt(id, 10))
        const selectedTypes = (eventTypes || []).filter((type) => ids.includes(type.id))
        setSelectedEventTypes(selectedTypes)
      }
    }
  }, [eventTypes])

  const onChangeEventTypesHandler = (event: SyntheticEvent, newTypes: EventType[]) => {
    setSelectedEventTypes(newTypes)
  }
  const applyFilters = () => {
    applyEventTypeFilter()
    applyHideServiceAccountFilter()
  }

  const applyEventTypeFilter = () => {
    if (selectedEventTypes.length === 0) {
      removeQueryParams([SearchParamsKeys.EventTypes])
    } else {
      setQueryParams({
        [SearchParamsKeys.EventTypes]: selectedEventTypes.map((eventType) => eventType.id).join(','),
      })
    }
  }

  const applyHideServiceAccountFilter = () => {
    if (hideServiceAccount === false) {
      removeQueryParams([SearchParamsKeys.HideServiceAccount])
    } else {
      setQueryParams({
        [SearchParamsKeys.HideServiceAccount]: 'true',
      })
    }
  }

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <Autocomplete
          multiple
          id="event-types"
          options={eventTypes || []}
          loading={isLoadingEventTypes}
          disableCloseOnSelect
          getOptionLabel={(option) => option.displayName || ''}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.displayName}
            </li>
          )}
          style={{ width: 500 }}
          renderInput={(params) => (
            <TextField {...params} label="Event types" placeholder="Event name..." />
          )}
          value={selectedEventTypes}
          onChange={onChangeEventTypesHandler}
        />
      </Grid>
      <Grid item>
        <Switch
          name="hideServiceAccount"
          label="Hide Service Account"
          SwitchProps={{
            onChange(_, checked) {
              setHideServiceAccount(checked)
            },
            checked: hideServiceAccount,
          }}
        />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={applyFilters}
        >
          Apply
        </Button>
      </Grid>
    </Grid>
  )
}

export const EventListFilters: FC = () => (
  <FiltersDataLoader>
    <EventListFiltersContent />
  </FiltersDataLoader>
)
