import React from "react"
import { GridColDef } from '@mui/x-data-grid-premium'
import format from "date-fns/format"
import { InnerLink } from "src/components/ui"
import { Event } from "src/api"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { ImagesListItem } from "src/contexts"

export namespace EventColumns {
  export const Name: GridColDef<Event> = {
    field: "eventCardRenderingData.eventName",
    headerName: "Name",
    type: "string",
    minWidth: 250,
    editable: false,
    valueGetter: ({ row }) => row.eventCardRenderingData?.eventName || row.eventCardRenderingData?.eventDetails || 'Name is not defined',
    renderCell: ({ value, row }) => (
      <InnerLink to={`/events/${row.id}`}>{value}</InnerLink>
    )
  }

  export const Type: GridColDef<Event> = {
    field: "type.name",
    headerName: "Type",
    type: "string",
    minWidth: 200,
    editable: false,
    valueGetter: ({ row }) => row?.type?.displayName || "-",
  }

  export const Project: GridColDef<Event> = {
    field: "project.name",
    headerName: "Project",
    type: "string",
    minWidth: 200,
    editable: false,
    valueGetter: ({ row }) => row?.project?.name || null,
    renderCell: ({ value, row }) => {
      if (!value) return '-'
      return (
        <InnerLink to={`/projects/${row.project?.id}`}>{value}</InnerLink>
      )
    }
  }

  export const Contractor: GridColDef<Event> = {
    field: "contractor.name",
    headerName: "Contractor",
    type: "string",
    minWidth: 200,
    editable: false,
    valueGetter: ({ row }) => row?.contractor?.name || row?.project?.contractor?.name || null,
    renderCell: ({ value, row }) => {
      if (!value) return '-'
      const contractorId = row.contractor?.id || row.project?.contractor?.id
      return (
        <InnerLink to={`/contractors/${contractorId}`}>{value}</InnerLink>
      )
    }
  }

  export const Section: GridColDef<Event> = {
    field: "projectSection.name",
    headerName: "Section",
    type: "string",
    minWidth: 200,
    editable: false,
    valueGetter: ({ row }) => row?.projectSection?.name || null,
    renderCell: ({ value, row }) => {
      if (!value) return '-'
      return (
        <InnerLink to={`/sections/${row.projectSection?.id}`}>{value}</InnerLink>
      )
    }
  }

  export const Author: GridColDef<Event> = {
    field: "eventCardRenderingData.author",
    headerName: "Author",
    type: "string",
    minWidth: 150,
    editable: false,
    valueGetter: ({ row }) => row?.eventCardRenderingData?.author || '-',
    renderCell: ({ value, row }) => {
      if (!value) return '-'
      return (
        <InnerLink to={`/users/${row.authorId}`}>{value}</InnerLink>
      )
    }
  }

  export const CreatedAt: GridColDef<Event> = {
    field: "createdAt",
    headerName: "Created At",
    type: "string",
    minWidth: 200,
    editable: false,
    valueFormatter: ({ value }) => value ? format(new Date(value), "PPpp") : "-",
  }

  export const Distance: GridColDef<Event> = {
    field: "data.distance",
    headerName: "Distance",
    type: "string",
    minWidth: 130,
    editable: false,
    valueGetter: ({ row }) => row?.data?.distance || '-',
  }

  export const Device: GridColDef<Event> = {
    field: "data.appDevice",
    headerName: "Device",
    type: "string",
    minWidth: 200,
    editable: false,
    valueGetter: ({ row }) => row?.data?.appDevice || '-',
  }

  export const AppVersion: GridColDef<Event> = {
    field: "data.appVersion",
    headerName: "App Version",
    type: "string",
    minWidth: 200,
    editable: false,
    valueGetter: ({ row }) => row?.data?.appVersion || '-',
  }

  export const Photo = (openGallery: (imgs: ImagesListItem[]) => void): GridColDef<Event> => ({
    field: "photo",
    headerName: "Photo",
    type: "string",
    minWidth: 150,
    editable: false,
    renderCell: ({ row }) => {
      const photoAmount = row.eventCardRenderingData?.photos?.length || 0
      if (!row.eventCardRenderingData?.photos || photoAmount === 0) {
        return <Typography variant="body2">-</Typography>
      }

      const images: { src: string }[] = row.eventCardRenderingData.photos
        .map((photo) => ({ src: photo.originalLink || '' }))
        .filter((photo) => Boolean(photo.src))
      return (
        <Box onClick={() => openGallery(images)}>
          <Typography
            variant="body2"
            color='#037BEB'
            sx={{ cursor: 'pointer' }}
          >
            {images.length} photo
          </Typography>
        </Box>
      )
    },
  })
}
