import { FC } from "react"
import { Typography, Tooltip } from "@mui/material"
import { GridRenderCellParams } from "@mui/x-data-grid-premium"
import { ProductRequest, ProductRequestDoneResponseStatusEnum } from "src/api"

interface Props extends GridRenderCellParams<ProductRequest, ProductRequestDoneResponseStatusEnum> {}

export const SummaryStatusInlineCell: FC<Props> = ({
  value,
  row,
}) => {
  return (
    <Tooltip title={row.doneResponseSummary || ''}>
      <Typography variant="body2" textOverflow="ellipsis" overflow="hidden">{value === 'sent' ? "Sent" : "Not sent"}</Typography>
    </Tooltip>
  )
}


