import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { APIList } from 'src/interfaces'
import { Entities } from 'src/enums'
import { Event } from 'src/api'
import { useApiInstance } from './useApiInstance'
import {
  buildRequestParams,
  ObjectType,
  transformPage,
  transformPerPage,
} from './helpers'

interface RequestParams {
  params?: Partial<AdminEventListGetParams>;
  paramsTransform?: Array<(params: Record<string, any>) => Record<string, any>>;
  options?: Omit<
    UseQueryOptions<APIList<Event>, Error>,
    'queryKey' | 'queryFn'
  >;
  enabled?: boolean
}

interface AdminEventListGetParams {
  limit: number;
  offset: number;
  sort: string;
  projectId: number;
  contractorId: number;
  userId: number;
  eventTypes: string;
  hideServiceAccount: boolean;
}

const schema: ObjectType['schema'] = {
  limit: 'number',
  offset: 'number',
  sort: 'string',
  projectId: 'number',
  contractorId: 'number',
  userId: 'number',
  eventTypes: 'string',
  hideServiceAccount: 'boolean',
}

export const useEventListRequest = ({
  params = {},
  paramsTransform = [],
  options,
  enabled = true,
}: RequestParams = {}) => {
  const api = useApiInstance()

  const transformedParams = [
    transformPerPage,
    transformPage,
    ...paramsTransform,
  ].reduce((param, transform) => transform(param), params)

  const queryParams = buildRequestParams<AdminEventListGetParams>(
    transformedParams,
    schema,
  )

  return useQuery<APIList<Event>, Error>(
    [Entities.Events, JSON.stringify(transformedParams)],
    async () => {
      const {
        limit,
        offset,
        sort,
        projectId,
        userId,
        eventTypes,
        hideServiceAccount,
        contractorId,
      } = queryParams
      const { data } = await api.v1AdminEventsGet(
        limit,
        offset,
        sort,
        projectId,
        userId,
        eventTypes,
        hideServiceAccount,
        contractorId,
      )
      return data as APIList<Event>
    },
    {
      enabled: Object.keys(transformedParams).length > 0 && enabled,
      ...options,
    },
  )
}
