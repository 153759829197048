import {
  ProductDraftCommonTipTypeEnum,
  ProductDraftSafetyTipTypeEnum,
  ProductTip,
  ProductTipEditing,
} from '../api'

export const commonTipOptions = [
  {
    id: ProductDraftCommonTipTypeEnum.Default,
    name: 'Use category default tips',
  },
  { id: ProductDraftCommonTipTypeEnum.Custom, name: 'Use custom tips' },
]

export const safetyTipOptions = [
  {
    id: ProductDraftSafetyTipTypeEnum.Default,
    name: 'Use category default tips',
  },
  { id: ProductDraftSafetyTipTypeEnum.Custom, name: 'Use custom tips' },
]

export const convertProductTipsToEditing = (
  items: ProductTip[],
): ProductTipEditing[] => items
  .map(({ id, typeId, content }) => ({ id, typeId, content }))
  .filter((item) => !!item.typeId) as ProductTipEditing[]
