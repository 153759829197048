import React, { FC, SyntheticEvent, useCallback, useState, PropsWithChildren } from "react"
import { Grid, Tab, Tabs, Box } from "@mui/material"
import { useLocation } from "react-router-dom"
import { useQueryParams } from "src/hooks/ui"

const buildStatus = (status: string) => status.split(" ").join("")

interface TabPanelProps {
  index: number;
  value: number;
}

const TabPanel: FC<PropsWithChildren<TabPanelProps>> = ({ index, value, children }) => {
  return index === value ? (
    <Box mt={2} height="inherit">
      {children}
    </Box>
  ) : null
}

interface Status {
  status: string;
  disabled?: boolean;
  content: React.ReactElement;
}

interface TabGroupFilterProps {
  name: string;
  statusList: Status[];
  paramsToRemove?: string[];
}

export const TabGroupFilter: FC<TabGroupFilterProps> = ({
  name,
  statusList,
  paramsToRemove = [],
}) => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const statusQueryParam = queryParams.get(name)
  const initial = buildStatus(statusQueryParam || statusList[0]?.status || "")
  const buildState = statusList.length > 1 ? useQueryParams : useState
  const [params, setParams] = buildState({ [name]: initial })
  const current = statusList.findIndex(
    (el) => buildStatus(el.status) === params[name]
  )

  const handleChange = useCallback(
    async (_: SyntheticEvent, index: number) => {
      await setParams({ [name]: buildStatus(statusList[index].status) }, [
        name,
        ...paramsToRemove,
      ])
    },
    [name, paramsToRemove, setParams, statusList]
  )
  if (current === -1) return null

  return (
    <Grid alignItems="stretch" container direction="column">
      <Grid item xs>
        <Tabs
          sx={{ borderBottom: 1, borderColor: "divider" }}
          value={current}
          onChange={handleChange}
        >
          {statusList.map(({ status, disabled }) => (
            <Tab key={status} disabled={disabled} label={status} />
          ))}
        </Tabs>
      </Grid>
      <Grid item xs width="100%" height="100%">
        {statusList.map(({ status, content }, idx) => (
          <TabPanel key={idx} value={current} index={idx}>
            {content}
          </TabPanel>
        ))}
      </Grid>
    </Grid>
  )
}
