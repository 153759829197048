import React, { FC, ReactElement, useState, useCallback, useEffect } from 'react'
import { Box, Button, CircularProgress, Grid, Paper } from '@mui/material'
import {
  DataGridPremium,
  useGridApiRef,
  GridPaginationModel,
  gridFilteredTopLevelRowCountSelector,
  gridFilterModelSelector,
  GridPinnedColumns,
  GridRowId,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridValidRowModel,
} from '@mui/x-data-grid-premium'
import { Sensor, ThingspaceSensor } from 'src/api'
import { useAddPopupMessage, useAuthorizedState, useFillMissedUplinks, useQueryParams } from 'src/hooks/ui'
import { SearchParamsKeys, SensorButtons } from 'src/enums'
import { config, defaultRowsValuePerPage } from 'src/config'
import { SensorHistoricalDataModal } from 'src/components/forms'
import { TableToolbar } from 'src/components/ui'

const defaultRowsPerPageOptions = defaultRowsValuePerPage
const defaultPerPage = 25
const defaultPage = 1
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}

interface CommonSensorListProps<Entity extends GridValidRowModel = any> {
  columns: GridColDef<Entity>[]
  data: Entity[]
  isLoading: boolean
  count: number
  filters?: ReactElement
  autoHeight?: boolean
  checkboxSelection?: boolean
  buttons?: SensorButtons[]
  exportedFileName?: string
  processRowUpdate?: (...props: any) => any
}

export const CommonSensorList: FC<CommonSensorListProps> = ({
  columns,
  data,
  isLoading,
  count,
  exportedFileName,
  filters = null,
  autoHeight = false,
  checkboxSelection = false,
  buttons = [],
  processRowUpdate = () => {},
}) => {
  const token = useAuthorizedState()
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)
  const apiRef = useGridApiRef()
  const [rowCountState, setRowCountState] = useState(count)
  const [pinnedColumns, setPinnedColumns] = useState<GridPinnedColumns>({
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "uniqueId"],
    right: ["actions"]
  })
  const [selectedSensorIds, setSelectedSensorIds] = useState<GridRowId[]>([])
  const [isLoadingLabels, setIsLoadingLabels] = useState<boolean>(false)
  const addPopupMessage = useAddPopupMessage()
  const { fillMissedUplinksBulk, isLoadingBulk: isLoadingFillMissedUplinksRequest } = useFillMissedUplinks()

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      count !== undefined ? count : prevRowCountState
    )
  }, [count, setRowCountState])

  const handleChangePaginationModel = async (pagination: GridPaginationModel): Promise<void> => {
    const newPage = pagination.page + 1
    setQueryParams({
      [SearchParamsKeys.Page]: `${newPage}`,
      [SearchParamsKeys.RowsPerPage]: `${pagination.pageSize}`,
    }, [
      SearchParamsKeys.Page,
      SearchParamsKeys.RowsPerPage,
    ])
  }

  const handlePinnedColumnsChange = useCallback(
    (updatedPinnedColumns: GridPinnedColumns) => {
      setPinnedColumns(updatedPinnedColumns)
    },
    []
  )

  const handleChangeState = async () => {
    const filterModel = gridFilterModelSelector(apiRef.current.state)
    if (filterModel.items.length > 0 && filterModel.items[0].value) {
      const visibleRowCount = gridFilteredTopLevelRowCountSelector(apiRef.current.state)
      if (visibleRowCount) setRowCountState(visibleRowCount)
    }
    else setRowCountState(count)
  }

  const downloadLabels = async () => {
    if (!selectedSensorIds || selectedSensorIds.length === 0) return

    try {
      setIsLoadingLabels(true)
      const { apiPath, host } = config
      const basePath = `${host}/${apiPath}`
      const sensorUniqueIds = data.filter((sensor) => selectedSensorIds.includes(sensor.id)).map((sensor) => sensor.uniqueId)
      const response = await fetch(`${basePath}/v1/admin/sensors/generate-labels?sensorUniqueIds=${sensorUniqueIds.join(',')}&Authorization=${token}`, {
        headers: {
          'User-Agent': navigator.userAgent,
          'client-platform': 'admin'
        }
      })
      const file = await response.blob()
      const href = window.URL.createObjectURL(file)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', `labels_${new Date().toISOString()}.zip`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.error(error)
      addPopupMessage({ text: 'Error during downloading labels', type: "error" })
    } finally {
      setIsLoadingLabels(false)
    }
  }

  const onFillMissedUplinks = (date: string) => {
    if (!selectedSensorIds || selectedSensorIds.length === 0) return

    const imeis = data.filter((sensor) => selectedSensorIds.includes(sensor.id)).map((sensor) => String(sensor.imei))
    fillMissedUplinksBulk(date, imeis)
  }

  const renderButtons = (): ReactElement | null => {
    if (selectedSensorIds.length === 0) return null

    const elements: ReactElement[] = []
    if (buttons.includes(SensorButtons.DownloadLabels)) {
      elements.push(<Box pt={2}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          type="submit"
          disabled={isLoadingLabels}
          onClick={downloadLabels}
        >
          {
            isLoadingLabels ? <CircularProgress color="info" size={26} /> : 'Download archive with labels'
          }
        </Button>
      </Box>)
    }
    if (buttons.includes(SensorButtons.GetHistoricalData)) {
      elements.push(<Box pt={2}>
        <SensorHistoricalDataModal
          onFillMissedUplinks={onFillMissedUplinks}
          isLoading={isLoadingFillMissedUplinksRequest}
        />
      </Box>)
    }

    return (
      <Grid container item xs="auto" spacing={2}>
        {elements.map((element, index) => (
          <Grid key={index} item xs="auto">{element}</Grid>
        ))}
      </Grid>
    )
  }

  const page = queryParams["page"] ? parseInt(queryParams["page"], 10) - 1 : defaultPage - 1
  const pageSize = queryParams["rowsPerPage"]
    ? parseInt(queryParams["rowsPerPage"], 10)
    : defaultPerPage

  return (
    <Box height='100%'>
      {
        filters || selectedSensorIds.length > 0 ? (
          <Grid container item flexDirection="column" justifyContent="space-between" mb={2}>
            {filters && <Grid item xs={selectedSensorIds.length ? "auto" : 12}>{filters}</Grid>}
            {renderButtons()}
          </Grid>
        ) : null
      }
      <Paper sx={{ flexGrow: 1, minHeight: '200px', height: '100%', maxHeight: '800px' }}>
        <DataGridPremium<Sensor | ThingspaceSensor>
          apiRef={apiRef}
          pagination
          autoHeight={autoHeight}
          pinnedColumns={pinnedColumns}
          checkboxSelection={checkboxSelection}
          disableRowSelectionOnClick
          onRowSelectionModelChange={setSelectedSensorIds}
          rows={data}
          columns={columns}
          rowCount={rowCountState}
          loading={isLoading}
          paginationModel={{
            page: page as number,
            pageSize: pageSize as number
          }}
          rowThreshold={2}
          columnThreshold={2}
          paginationMode="server"
          pageSizeOptions={defaultRowsPerPageOptions}
          onPaginationModelChange={handleChangePaginationModel}
          onStateChange={handleChangeState}
          localeText={{
            columnMenuSortAsc: "Sort A-Z",
            columnMenuSortDesc: "Sort Z-A",
          }}
          slots={{
            toolbar: () => <TableToolbar exportedFileName={exportedFileName} />,
          }}
          onPinnedColumnsChange={handlePinnedColumnsChange}
          sx={(theme) => ({
            border: "none",
            "& .MuiDataGrid-columnHeaderTitleContainerContent": {
              paddingLeft: "5px",
              overflow: "visible",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: 1,
              fontSize: "13px",
              fontWeight: 400,
              color: "rgba(0, 0, 0, 0.6)",
              overflow: "visible",
            },
          })}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={(error) => {
            if (error) addPopupMessage({ text: error, type: "error" })
          }}
        />
      </Paper>
    </Box>
  )
}
