import React, { FC, useState, useCallback, useEffect } from 'react'
import { ApexOptions } from 'apexcharts'
import { format } from 'date-fns'
import { Box, CircularProgress, Grid, IconButton, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded'
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardRounded'
import CloseIcon from '@mui/icons-material/CloseRounded'
import { useUnstableSensorsChartRequest } from 'src/hooks/api'
import { Chart } from 'src/components/ui'
import { AggregatedUnstableSensor } from 'src/api'

const STEP = 30

export const UnstableSensorsChart: FC = () => {
  const [timeFrom, setTimeFrom] = useState<Date>(new Date(new Date().setDate(new Date().getDate() - (STEP - 1))))
  const [timeTo, setTimeTo] = useState<Date>(new Date())
  const [unstableSensorList, setUnstableSensorList] = useState<AggregatedUnstableSensor[]>([])

  const {
    data: aggregatedUnstableSensorList,
    isInitialLoading,
    isRefetching,
    refetch: refetchUnstableSensorList,
  } = useUnstableSensorsChartRequest({
    params: {
      timeFrom: timeFrom.toISOString(),
      timeTo: timeTo.toISOString(),
    },
    options: { enabled: false },
  })

  useEffect(() => {
    setUnstableSensorList(aggregatedUnstableSensorList || [])
  }, [aggregatedUnstableSensorList])

  useEffect(() => {
    if (timeFrom && timeTo) refetchUnstableSensorList()
  }, [timeFrom, timeTo, refetchUnstableSensorList])

  const isLastPeriod = useCallback(() => {
    return timeTo.getDate() === new Date().getDate()
      && timeTo.getMonth() === new Date().getMonth()
      && timeTo.getFullYear() === new Date().getFullYear()
  }, [timeTo])()

  const handleGoBack = () => {
    if (isRefetching) return

    const newTimeFrom = new Date(new Date(timeFrom).setDate(new Date(timeFrom).getDate() - STEP))
    const newTimeTo = new Date(new Date(timeTo).setDate(new Date(timeTo).getDate() - STEP))
    setTimeFrom(newTimeFrom)
    setTimeTo(newTimeTo)
  }

  const handleGoForward = () => {
    if (isLastPeriod || isRefetching) return

    const newTimeFrom = new Date(new Date(timeFrom).setDate(new Date(timeFrom).getDate() + STEP))
    const newTimeTo = new Date(new Date(timeTo).setDate(new Date(timeTo).getDate() + STEP))
    setTimeFrom(newTimeFrom)
    setTimeTo(newTimeTo)
  }

  const resetRange = () => {
    const newTimeFrom = new Date(new Date(new Date().setDate(new Date().getDate() - STEP)))
    const newTimeTo = new Date()
    setTimeFrom(newTimeFrom)
    setTimeTo(newTimeTo)
  }

  const getSensorBarChartData = useCallback(
    (): ApexOptions['series'] => {
      const chartData: number[] = []
      for (let i = 0; i < STEP; i++) {
        const shiftedTime = new Date(new Date(timeTo).setUTCDate(new Date(timeTo).getUTCDate() - i))
        const unstableSensor = unstableSensorList.find((item) => (
          new Date(item.date).getUTCFullYear() === shiftedTime.getUTCFullYear()
          && new Date(item.date).getUTCMonth() === shiftedTime.getUTCMonth()
          && new Date(item.date).getUTCDate() === shiftedTime.getUTCDate()
        ))
        chartData.unshift(unstableSensor?.numberOfUnstableSensors || 0)
      }
      return [
        {
          name: 'Unstable sensors',
          data: chartData,
        }
      ]
    },
    [unstableSensorList, timeTo]
  )

  const getLabels = useCallback((): string[] => {
    const labels: string[] = []
    for (let i = 0; i < STEP; i++) {
      const shiftedTime = new Date(new Date(timeTo).setUTCDate(new Date(timeTo).getUTCDate() - i))
      labels.unshift(format(new Date(shiftedTime), 'MMM dd'))
    }
    return labels
  }, [timeTo])

  const chartOptions: ApexOptions = {
    chart: {
      type: 'bar',
      toolbar: {
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
        export: {
          csv: {
            headerCategory: 'Time',
          },
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: getLabels(),
    },
    yaxis: {
      labels: {
        formatter: (val) => String(parseInt(val.toString(), 10))
      }
    },
    tooltip: {
      y: {
        formatter: (val) => String(parseInt(val.toString(), 10))
      }
    },
  }

  return (
    <Grid container spacing={2} direction='column'>
      <Grid item>
        <Typography variant='h6'>Unstable In-Use sensors chart</Typography>
      </Grid>
      <Grid item container alignItems="center">
        <Grid item>
          <IconButton aria-label="go-back" color="primary" onClick={handleGoBack} disabled={isRefetching}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item px={1}>
          <Typography variant="body1">
            {`${format(timeFrom, 'PP')} - ${format(timeTo, 'PP')}`}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton aria-label="go-forward" color="primary" onClick={handleGoForward} disabled={isLastPeriod || isRefetching}>
            <ArrowForwardIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton aria-label="reset" color="primary" onClick={resetRange} disabled={isLastPeriod || isRefetching}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item container>
        <Box height="400px" ml={-2} width="calc(100% + 16px)" position="relative">
          {
            isInitialLoading && (
              <>
                <Box
                  position="absolute"
                  width="100%"
                  height="100%"
                  zIndex={1}
                  sx={{
                    background: "grey",
                    opacity: 0.1,
                  }}
                />
                <Box
                  position="absolute"
                  width="100%"
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress color="primary" size={26} />
                </Box>
              </>
            )
          }
          <Chart options={chartOptions} data={getSensorBarChartData()} />
        </Box>
      </Grid>
    </Grid>
  )
}
