import React, { FC, useContext } from "react"
import {
  DialogActions,
  Button,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material"
import { Dialog } from "src/components/ui"
import {
  ProductDraft,
  ProductDraftCommonTipTypeEnum,
  ProductDraftSafetyTipTypeEnum,
  ProductTipCreation,
  ProductTipEditing,
} from "src/api"
import { Form, FormikContext, useFormik } from "formik"
import {
  commonTipOptions,
  convertProductTipsToEditing,
  safetyTipOptions,
} from "src/utils/tips"
import { ProductTipForm } from "./productTipForm"
import { ProductDraftsContext } from "../../../contexts/productDraftsContext"

export interface EditProductTipsState {
  id: number;
  commonTipType: ProductDraftCommonTipTypeEnum;
  safetyTipType: ProductDraftSafetyTipTypeEnum;
  commonCustomTips: ProductTipCreation[] | ProductTipEditing[];
  safetyCustomTips: ProductTipCreation[] | ProductTipEditing[];
}

export interface TechEditProductTipsState {
  commonTipType: ProductDraftCommonTipTypeEnum;
  safetyTipType: ProductDraftSafetyTipTypeEnum;
  commonTipList: ProductTipCreation[] | ProductTipEditing[];
  safetyTipList: ProductTipCreation[] | ProductTipEditing[];
  commonNewTip?: string;
  safetyNewTip?: string;
}

const defaultInitial: TechEditProductTipsState = {
  safetyTipType: ProductDraftSafetyTipTypeEnum.Default,
  commonTipType: ProductDraftCommonTipTypeEnum.Default,
  commonNewTip: "",
  safetyNewTip: "",
  commonTipList: [],
  safetyTipList: [],
}

interface EditProductTipsModalProps {
  open: boolean;
  productDraft: ProductDraft | null;
  onSave: (values: EditProductTipsState) => void;
  onCancel: () => void;
}

export const EditProductTipsModal: FC<EditProductTipsModalProps> = ({
  open,
  productDraft,
  onSave,
  onCancel,
}) => {
  const { safetyTipTypeId, defaultTips, commonTipTypeId } =
    useContext(ProductDraftsContext)

  const defaultProductTips = defaultTips.filter(
    ({ productTypeId }) => productTypeId === productDraft?.typeId
  )

  const defaultCommonTips = defaultProductTips.filter(
    ({ typeId }) => typeId === commonTipTypeId
  )
  const defaultSafetyTips = defaultProductTips.filter(
    ({ typeId }) => typeId === safetyTipTypeId
  )

  const getInitialValues = (): TechEditProductTipsState => {
    if (!productDraft) {
      return defaultInitial
    }
    const commonTipType =
      productDraft.commonTipType || ProductDraftCommonTipTypeEnum.Default
    const safetyTipType =
      productDraft.safetyTipType || ProductDraftSafetyTipTypeEnum.Default

    return {
      ...defaultInitial,
      commonTipType,
      commonTipList: convertProductTipsToEditing(
        productDraft?.commonCustomTips ?? []
      ),
      safetyTipType,
      safetyTipList: convertProductTipsToEditing(
        productDraft?.safetyCustomTips ?? []
      ),
    }
  }

  const handleSubmit = (data: TechEditProductTipsState) => {
    if (!productDraft) {
      return
    }

    const commonCustomTips =
      data.commonTipType === ProductDraftCommonTipTypeEnum.Custom
        ? data.commonTipList
        : []
    const safetyCustomTips =
      data.safetyTipType === ProductDraftSafetyTipTypeEnum.Custom
        ? data.safetyTipList
        : []

    onSave({
      id: productDraft.id,
      commonTipType: data.commonTipType,
      safetyTipType: data.safetyTipType,
      commonCustomTips,
      safetyCustomTips,
    })
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(),
    onSubmit: handleSubmit,
  })

  const { submitForm, resetForm } = formik

  const handleClose = () => {
    onCancel()
    resetForm()
  }

  return (
    <FormikContext.Provider value={formik}>
      <Form>
        <Dialog
          open={open}
          title={<DialogTitle>Edit Product Tips</DialogTitle>}
          scroll="paper"
          DialogProps={{ fullWidth: true, maxWidth: "sm" }}
          actions={
            <DialogActions>
              <Button variant="contained" size="large" onClick={submitForm}>
                Save and publish
              </Button>
              <Button variant="outlined" size="large" onClick={handleClose}>
                Cancel
              </Button>
            </DialogActions>
          }
        >
          {commonTipTypeId && (
            <Grid item xs={12}>
              <ProductTipForm
                type="common"
                typeId={commonTipTypeId}
                typeOptions={commonTipOptions}
                label="Install Tips"
                defaultTipList={defaultCommonTips}
                checkIfEditable={(v) =>
                  v === ProductDraftCommonTipTypeEnum.Custom
                }
                checkIfDefault={(v) =>
                  v === ProductDraftCommonTipTypeEnum.Default
                }
              />
            </Grid>
          )}
          {safetyTipTypeId && (
            <Grid item xs={12} mt={commonTipTypeId ? 4 : 0}>
              <ProductTipForm
                type="safety"
                typeId={safetyTipTypeId}
                typeOptions={safetyTipOptions}
                label="Safety Tips"
                defaultTipList={defaultSafetyTips}
                checkIfEditable={(v) =>
                  v === ProductDraftSafetyTipTypeEnum.Custom
                }
                checkIfDefault={(v) =>
                  v === ProductDraftSafetyTipTypeEnum.Default
                }
              />
            </Grid>
          )}
          <Grid item xs={12} mt={4}>
            <Typography color="error" align="center" variant="body2">
              Please note, tips are published to DB immediately
            </Typography>
          </Grid>
        </Dialog>
      </Form>
    </FormikContext.Provider>
  )
}
