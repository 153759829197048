import React, { FC, useCallback, useState } from "react"
import * as Yup from "yup"
import { Button, Grid, Box, CircularProgress, Chip, FilterOptionsState, Typography, Divider } from "@mui/material"
import { Formik, Form } from "formik"
import { TextField, PhoneInput, AutocompleteTextField, RadioGroup, Switch, FormCheckbox } from "src/components/ui"
import { createFilterOptions } from '@mui/material/Autocomplete'
import { ContractorEditingV2, Label, Contractor } from "src/api"
import { useNewLabelRequest } from "src/hooks/api"
import { useAddPopupMessage, useDialog } from "src/hooks/ui"
import { ChangeContractorTypeModal } from "src/components/sections"
import { DialogNames } from "src/enums"
import { RootContractorSelector } from "./rootContractorSelector"
import { phoneNullableValidation } from "../../../utils/validators"
import { formatPhone } from "../../../utils/formatters"
import { ImageUploadCard } from "../manufacturer/imageUploadCard"

const filter = createFilterOptions<string | Record<string, string>>()

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  address: Yup.string().nullable(),
  phone: phoneNullableValidation,
  email: Yup.string().nullable(),
  comment: Yup.string().nullable(),
  type: Yup.string().required(),
  rootContractorId: Yup.number()
    .when('type', {
      is: 'branch',
      then: (schema) => schema.required('You must select a HQ contractor'),
      otherwise: (schema) => schema.nullable(),
    })
    .typeError('HQ contractor is required')
})

export type ContractorType = 'standalone' | 'root' | 'branch'

export interface ContractorEditState extends ContractorEditingV2 {
  link?: string
  type?: ContractorType
}
const defaultInitial: ContractorEditState = {
  name: "",
  address1: void 0,
  phone: void 0,
  email: void 0,
  comment: void 0,
  type: 'standalone',
  rootContractorId: void 0,
  labels: [],
  link: "",
  isActiveDashboard: false,
}

interface ContractorEditFormProps {
  contractor: Contractor;
  initialValues?: ContractorEditState | null;
  onSubmit: (formState: ContractorEditState, photo: File | null) => void;
  isLoading?: boolean;
  labels?: Label[];
  refetchLabels?: () => Promise<any>
  setIsRemoved: (value: boolean) => void;
}

export const ContractorEditForm: FC<ContractorEditFormProps> = ({
  initialValues,
  contractor,
  onSubmit,
  isLoading = false,
  labels = [],
  refetchLabels,
  setIsRemoved,
}) => {
  const [checkedAppBranding, setCheckedAppBranding] = useState<boolean>(initialValues?.isEnabledAppBranding || contractor.isEnabledAppBranding || false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [isVisibleRootOptions, setVisibilityRootOptions] = useState<boolean>(false)
  const {
    openDialog: openDialogContractorType,
    closeDialog: closeDialogContractorType,
  } = useDialog(DialogNames.ChangeContractorType)
  const addMessage = useAddPopupMessage()
  const [contractorLabels, setContractorLabels] = useState<Label[]>(initialValues?.labels || [])
  const createLabelRequest = useNewLabelRequest()
  const onChangeLabels = async (values: unknown) => {
    const result: Label[] = []
    const newLabels: string[] = [];
    (values as any[]).forEach((label) => {
      const labelName = typeof label === 'string' ? label : label.name
      const existingLabel = labels.find((item) => item.name === labelName)
      if (existingLabel) {
        result.push(existingLabel)
      } else {
        newLabels.push(labelName)
      }
    })
    if (newLabels.length) {
      for await (const newLabelName of newLabels) {
        const newLabel = await createLabel(newLabelName)
        if (newLabel) result.push(newLabel)
      }
      if (refetchLabels) refetchLabels()
    }
    setContractorLabels(result)
  }

  const createLabel = (label: string): Promise<Label | null> => {
    return new Promise((resolve) => {
      createLabelRequest.mutate({
        name: label,
      }, {
        onSuccess: (data) => {
          resolve(data)
        },
        onError: (error) => {
          addMessage({ text: error.message, type: "error" })
          resolve(null)
        }
      })
    })
  }

  const onChangeAppBranding = (_: any, _checked: boolean) => {
    setCheckedAppBranding(_checked)
  }

  const renderTags = (value: readonly Label[] | string[], getTagProps: Function) => value.map((option: Label | string, index: number) => (
    <Chip variant="outlined" color="primary" label={(option as Label).name || option} {...getTagProps({ index })} />
  ))

  const getOptionLabel = (option: string | Record<string, string>) => {
    if (typeof option === 'string') return option
    if (option.rawTitle) return option.rawTitle
    return option.name
  }

  const filterOptions = (options: string[], params: FilterOptionsState<any>) => {
    const filtered = filter(options, params)

    const { inputValue } = params
    const isExisting = options.some((option) => inputValue === option)
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        rawTitle: `Add "${inputValue}"`,
        name: inputValue,
      })
    }

    return filtered
  }

  const updateFile = (value: File | null) => {
    setSelectedFile(value)
  }

  const toggleVisibilityRootOptins = () => {
    setVisibilityRootOptions(!isVisibleRootOptions)
  }

  const isRootContractor = useCallback(() => initialValues?.isRoot === true, [initialValues])()

  const renderRootContractorBlock = () => {
    if (isRootContractor && !isVisibleRootOptions) {
      return (
        <Grid item xs={12} my={1}>
          <Typography variant="body1" fontWeight={500}>HQ/Branch</Typography>
          <Typography variant="body2" my={1}>This is HQ contractor</Typography>
          <Button variant="text" color="error" onClick={toggleVisibilityRootOptins}>Change</Button>
        </Grid>
      )
    }

    return (
      <>
        <Grid item xs={12} my={1}>
          <RadioGroup
            name="type"
            label="HQ/Branch"
            options={[
              { label: 'Standalone contractor', value: 'standalone' },
              { label: 'HQ contractor', value: 'root' },
              { label: 'Branch contractor', value: 'branch' },
            ]}
          />
        </Grid>
        <Grid item xs={12} my={1}>
          <RootContractorSelector
            label="HQ contractor"
            name="rootContractorId"
            nameContractorTypeField="type"
            currentContractorId={initialValues?.rootContractorId || undefined}
            isRootContractor={isRootContractor}
            contractor={contractor}
          />
          {isRootContractor ? (
            <Button variant="text" color="error" onClick={toggleVisibilityRootOptins}>Hide</Button>
          ) : null}
        </Grid>
      </>
    )
  }

  const renderDashboardBlock = (values: ContractorEditState) => {
    if (values.type === 'root') {
      return (
        <>
          <Grid item xs={12} my={1}>
            <FormCheckbox
              name="isActiveDashboard"
              label="Activate dashboard"
              view="switch"
            />
          </Grid>
        </>
      )
    }
    return null
  }

  const editContractor = (data: ContractorEditState) => {
    const { type, ...values } = data
    const result: ContractorEditState = {
      ...values,
      labels: contractorLabels,
      link: undefined,
      isRoot: type === 'root',
      rootContractorId: type === 'branch' ? data.rootContractorId : null,
      isEnabledAppBranding: checkedAppBranding,
      phone: formatPhone(data.phone || ''),
      isActiveDashboard: type !== 'root' ? false : data.isActiveDashboard,
    }
    if (!result.address1) result.address1 = null
    if (!result.email) result.email = null
    if (!result.phone) result.phone = null
    if (!result.comment) result.comment = null
    onSubmit(result, selectedFile)
  }

  const requestToEditContractor = (data: ContractorEditState) => {
    if (initialValues?.isRoot === true && data.type !== 'root') {
      openDialogContractorType()
      return
    }
    editContractor(data)
  }

  return (
    <Formik
      initialValues={{ ...defaultInitial, ...initialValues }}
      onSubmit={requestToEditContractor}
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid container item xs={5} spacing={1}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label="Name"
                  required
                  TextFieldProps={{ placeholder: "Name of organization" }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="address1"
                  label="Address"
                  TextFieldProps={{ placeholder: "Address" }}
                />
              </Grid>
              <Grid item xs={12}>
                <PhoneInput
                  name="phone"
                  label="Phone number"
                  placeholder="Contact phone"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="email"
                  label="Email"
                  TextFieldProps={{ placeholder: "Contact email" }}
                />
              </Grid>
              {renderRootContractorBlock()}
              {renderDashboardBlock(values)}
              <Grid item xs={12} mb={2}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="comment"
                  label="Comment"
                  TextFieldProps={{ placeholder: "Comment" }}
                />
              </Grid>
              <Grid item xs={12}>
                <AutocompleteTextField
                  label="Labels"
                  name="labels"
                  labelField="name"
                  valueField="id"
                  options={labels.map((label) => label.name)}
                  isLoading={createLabelRequest.isLoading}
                  onChange={onChangeLabels}
                  initialValue={initialValues?.labels?.map((label) => label.name)}
                  width="100%"
                  AutocompleteProps={{
                    renderTags: renderTags,
                    getOptionLabel: getOptionLabel,
                    filterOptions: filterOptions,
                    value: contractorLabels.map((label) => label.name),
                    multiple: true,
                    freeSolo: true,
                    isOptionEqualToValue: void 0,
                    disableCloseOnSelect: true,
                    selectOnFocus: true,
                    clearOnBlur: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box pt={2}>
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isLoading}
                  >
                    {
                      isLoading ? <CircularProgress color="info" size={26} /> : 'Save'
                    }
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Grid item container flexDirection="column" xs={7} spacing={3}>
              <Grid item width="100%">
                <ImageUploadCard updateFile={updateFile} link={initialValues?.link} setIsRemoved={setIsRemoved}/>
              </Grid>
              <Grid item>
                <Switch
                  name="App branding"
                  label="App branding: display contractor logo in the app"
                  SwitchProps={{ checked: checkedAppBranding, onChange: onChangeAppBranding }}
                />
              </Grid>
            </Grid>
          </Grid>
          <ChangeContractorTypeModal
            onEditContractor={() => {
              closeDialogContractorType()
              editContractor(values)
            }}
            onCloseDialog={() => closeDialogContractorType()}
            contractorName={initialValues?.name as string}
            branches={contractor.branches || []}
            users={contractor.users || []}
          />
        </Form>
      )}
    </Formik>
  )
}
