import React, { FC } from 'react'
import { DataLoad } from 'src/components/ui'
import { useProjectListRequest, AdminProjectsGetParams } from 'src/hooks/api'
import { useQueryParamsState } from 'src/hooks/ui'
import ProjectColumns from 'src/components/columns/project'
import { ProjectList } from '../project'

type ProjectsRequestableListProps = Partial<AdminProjectsGetParams> & {
  noResultText?: string;
  showFooter?: boolean;
}

export const ProjectsRequestableList: FC<ProjectsRequestableListProps> = (
  props
) => {
  const params = useQueryParamsState()
  const { noResultText, showFooter, ...rest } = props
  const { isInitialLoading, isError, error, data } = useProjectListRequest({
    params: { ...params, ...rest },
  })
  return (
    <DataLoad isLoading={false} isError={isError} errorMessage={error?.message}>
      <ProjectList
        loading={isInitialLoading}
        count={data?.count || 0}
        data={data?.rows || []}
        noResultText={noResultText}
        showFooter={showFooter}
        columns={[
          ProjectColumns.Name,
          ProjectColumns.Location,
          ProjectColumns.ContractorName,
          ProjectColumns.UserName,
          ProjectColumns.ProjectTypeName,
          ProjectColumns.Size,
          ProjectColumns.ConnectedSensors,
          ProjectColumns.CreatedAt,
        ]}
      />
    </DataLoad>
  )
}
