export enum HTTPMethods {
  GET = 'GET',
  POST = 'POST',
  HEAD = 'HEAD',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum Entities {
  MobileSettings = 'mobile-settings',
  Contractors = 'contractors',
  Sensors = 'sensors',
  SensorState = 'sensor-state',
  Users = 'users',
  UserRoles = 'user-roles',
  UserMessageChannels = 'user-message-channels',
  UserSessions = 'user-sessions',
  Products = 'products',
  ProductsDrafts = 'products-drafts',
  ProductTips = 'product-tips',
  Manufacturers = 'manufacturers',
  ManufacturerAnnouncement = 'manufacturer-announcement',
  ManufacturerAnnouncementTypes = 'manufacturer-announcement-types',
  ManufacturerAnnouncementCategory = 'manufacturer-announcement-categories',
  ProductTypes = 'product-types',
  ProductTypesTips = 'product-types-tips',
  ProductPackageTypes = 'product-package-types',
  Projects = 'project',
  ProjectSections = 'project-sections',
  Events = 'events',
  EventTypes = 'event-types',
  BuildingTypes = 'building-types',
  ProjectTypes = 'project-types',
  Configuration = 'configuration',
  SensorConfiguration = 'sensor-configuration',
  SensorMissedReadings = 'sensor-missed-readings',
  SystemMessageTypes = 'system-message-types',
  SystemMessageTransportTypes = 'system-message-transport-types',
  SystemMessage = 'system-message',
  WagnerProbes = 'wagner-probes',
  ImportProductTemplateLink = 'import-proudcts-template-link',
  Release = 'release',
  Photo = 'photo',
  Label = 'label',
  SensorPastProjects = 'sensor-past-projects',
  Sections = 'sections',
  SensorReadout = 'sensor-readout',
  WagnerProbeReadings = 'wagner-probe-readings',
  Dashboard = 'dashboard',
  ProductRequest = 'product-requests',
  ProductRequestStatus = 'product-request-statuses',
  ReportTypes = 'report-types',
  Diagnostics = 'diagnostics',
  Reports = 'reports',
  UserNotifications = 'user-notifications',
  Tasks = 'tasks',
  ProductImport = 'product-import',
  AdvertisementPacket = 'advertisement-packet',
}

export enum LocalStorageKey {
  Auth = '__FLOORCLOUD_APP_AUTH__',
  NextSignInDate = '__FLOORCLOUD_APP_NEXT_SIGN_IN__',
}

export enum SensorStateList {
  All = 'all',
  New = 'new',
  Available = 'available',
  Assigned = 'assigned',
  InUse = 'in-use',
  Archived = 'archived',
  Thingspace = 'thingspace',
  Diagnostics = 'diagnostics',
}

export enum SearchParamsKeys {
  Page = 'page',
  Sort = 'sort',
  RowsPerPage = 'rowsPerPage',
  State = 'state',
  Tab = 'tab',
  Search = 'search',
  Type = 'typeId',
  Contractor = 'contractorId',
  Manufacturer = 'manufacturerId',
  NoShelfLife = 'noShelfLife',
  NoTips = 'noTips',
  NoRanges = 'noRanges',
  Role = 'role',
  LastActive = 'lastActive',
  InUse = 'inUse',
  LocationType = 'locationType',
  ViewType = 'viewType',
  BatteryLevel = 'batteryLevel',
  LowBattery = 'lowBattery',
  CurrentProject = 'currentProject',
  Chart = 'chart',
  WeakSignal = 'weakSignal',
  SignalStrength = 'signalStrength',
  LastPingMoreThanHour = 'lastPingMoreThanHour',
  LastPing = 'lastPing',
  SyncPeriodLessThanHour = 'syncPeriodLessThanHour',
  SyncPeriod = 'synchronizationPeriod',
  AgeOfReadingsMoreThanHour = 'ageOfReadingsMoreThanHour',
  MapLegendType = 'mapLegendType',
  IsAvailableInSystem = 'isAvailableInSystem',
  SubscriptionStatus = 'subscriptionStatus',
  CurrentSensorConfiguration = 'currentSensorConfiguration',
  EventTypes = 'eventTypes',
  HideServiceAccount = 'hideServiceAccount',
  HideArchivedProjects = 'hideArchivedProjects',
  HideArchivedTasks = 'hideArchivedTasks',
  ConnectivityToProject = 'connectedToProject',
  IsBleEnabled = 'isBleEnabled',
}

export enum DialogNames {
  AssignSensorToContractor = 'assignSensorToContractor',
  DeleteProduct = 'deleteProduct',
  MassEditProductDrafts = 'massEditProductDrafts',
  DeleteBuildingType = 'deleteBuildingType',
  DeleteProjectType = 'deleteProjectType',
  AssignForemanToProject = 'assignForemanToProject',
  RemoveForemanFromProject = 'removeForemanFromProject',
  DeleteProductType = 'deleteProductType',
  DeleteUser = 'deleteUser',
  DeleteRelease = 'deleteRelease',
  ArchiveUser = 'archiveUser',
  UnarchiveUser = 'unarchiveUser',
  DeleteManufacturer = 'deleteManufacturer',
  GetSensorHistoricalData = 'getSensorHistoricalData',
  DisconnectProbe = 'disconnectProbe',
  ProductRequestSummary = 'productRequestSummary',
  ProductRequestSummaryConfirmation = 'productRequestSummaryConfirmation',
  ChangeContractorType = 'changeContractorType',
  SensorGpxData = 'sensorGpxData',
  DeleteManufacturerAnnouncement = 'deleteManufacturerAnnouncement',
  CancelManufacturerAnnouncement = 'cancelManufacturerAnnouncement',
  ManageSensorBle = 'manageSensorBle',
  ArchiveSensor = 'archiveSensor',
  UnarchiveSensor = 'unarchiveSensor',
  ConfirmContractorDeactivation = 'confirmContractorDeactivation',
  DeleteManufacturerAnnouncementType = 'deleteManufacturerAnnouncementType',
  DeleteManufacturerAnnouncementCategory = 'deleteManufacturerAnnouncementCategory',
}

export enum UserRoles {
  Admin = 'admin',
  Foreman = 'foreman',
  PM = 'pm',
  ServiceAccount = 'service',
  Owner = 'owner',
}

export enum UserFilterRoles {
  Contractor = 'contractor', // PMs and Foreman
  Admin = 'admin',
}

export enum SensorModels {
  VERIZON_GAT = 'verizon_gat',
  TEKTELIK_TUNDRA = 'tektelik_tundra',
}

export enum XlsxAllowedTypes {
  Xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  Xls = 'application/vnd.ms-excel',
}
export enum ImageAllowedTypes {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  HEIC = 'image/heic',
  HEIF = 'image/heif',
  HEIC_SEQUENCE = 'image/heic-sequence',
  HEIF_SEQUENCE = 'image/heif-sequence',
}

export enum LocationTypes {
  Cellural = 'cellural',
  GPS = 'gps',
}

export enum ViewTypes {
  List = 'list',
  Map = 'map',
}

export enum SensorFilters {
  Contractor = 'contractor',
  LowBattery = 'lowBattery',
  WeakSignal = 'weakSignal',
  LastPingMoreThanHour = 'lastPing',
  SyncPeriod = 'synchronizationPeriod',
  ReadingsAge = 'readingsAge',
  LocationType = 'locationType',
  AvailabilityInSystem = 'availabilityInSystem',
  CurrentSensorConfiguration = 'currentSensorConfiguration',
  BleStatus = 'bleStatus',
}

export enum SensorButtons {
  DownloadLabels = 'downloadLabels',
  GetHistoricalData = 'getHistoricalData'
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum MapLegendTypes {
  BatteryLevel = 'batteryLevel',
  SignalStrength = 'signalStrength',
}

export enum BatteryLevels {
  Low = 'low',
  Good = 'good',
}

export enum SignalStrengths {
  Good = 'good',
  Fair = 'fair',
  Weak = 'weak',
}

export enum ReadingType {
  Acceleration = 'acceleration',
  Battery = 'battery',
  Dewpoint = 'dewpoint',
  Humidity = 'humidity',
  Light = 'light',
  LocationCellular = 'locationCellular',
  LocationGps = 'locationGps',
  Orientation = 'orientation',
  Pressure = 'pressure',
  SignalStrength = 'signalStrength',
  Temperature = 'temperature',
  Diagnostic = 'diagnostic',
  Configuration = 'configuration',
}

export enum ContractorFilters {
  Subscription = 'subscription'
}

export enum RapidRhFilters {
  ConnectivityToProject = 'connectivityToProject',
}

export enum FloorcloudSensorLocationType {
  GPS = 'gps',
  CELLULAR = 'cellid'
}
