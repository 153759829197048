import { FC, useCallback, useState } from 'react'
import { Grid, IconButton, Theme, Typography, Box, List } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton'
import Divider from '@mui/material/Divider'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { styled } from '@mui/system'
import { Settings, Logout } from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useNewProductRequestNumber, useSignOut } from 'src/hooks/ui'
import { Stand, config } from 'src/config'
import { SidebarLink, SidebarLinkProps } from './sidebarLink'
import packageJson from '../../../package.json'
import { ContentBox } from '../ui'
import { LogoHorizontalWhite } from '../icons'

type MenuItemType = 'productRequest'
type BuildLinkProps = {
  link: SidebarLinkProps
  idx: number | string
}
type GetLinksResult = {
  primary: SidebarLinkProps[]
  secondary: SidebarLinkProps[]
}

const StyledListItem = styled(ListItemButton)`
  justify-content: space-between;
  padding: ${({ theme }: { theme: Theme } & any) =>
    theme.typography.pxToRem(4)};
  padding-left: 0;
`

const MenuItem = styled(Box)`
  cursor: pointer;
  justify-content: space-between;
  padding: ${({ theme }: { theme: Theme } & any) =>
    theme.typography.pxToRem(4)};
  padding-left: 0;
  :hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
  }
`

const BuildLinkList: FC<BuildLinkProps> = (props) => {
  const { link, idx } = props
  const { title, to, badgeContent, nestedElements } = link
  const { pathname } = useLocation()
  const [open, setOpen] = useState<boolean>(() => {
    const sameOrNestedRoute = pathname === to
      || (Array.isArray(nestedElements) && nestedElements.some((nested) => nested.to && pathname.includes(nested.to)))
    return sameOrNestedRoute
  })
  if (nestedElements && nestedElements.length > 0) {
    return (
      <Box key={idx}>
        <StyledListItem onClick={() => setOpen(!open)}>
          <SidebarLink
            title={title}
            to={to}
            nestedElements={nestedElements}
            badgeContent={badgeContent}
            isVisibleBadge={!open}
          />
          {open ? <ExpandLess sx={{ fill: 'white' }} /> : <ExpandMore sx={{ fill: 'white' }} />}
        </StyledListItem>
        <Collapse in={open} timeout="auto">
          <List component="div" sx={{ paddingLeft: 2 }}>
            {nestedElements.map((nestedElement, nestedIdx) => BuildLinkList({
              link: nestedElement,
              idx: `nested_${nestedIdx}`
            }))}
          </List>
        </Collapse>
      </Box>
    )
  }

  return (
    <MenuItem key={idx}>
      <SidebarLink title={title} to={to} badgeContent={badgeContent} />
    </MenuItem>
  )
}

const getLinks = (getBudgeContent: (menuItem: MenuItemType) => number): GetLinksResult => (
  {
    primary: [
      {
        title: 'Dashboard',
        to: '/dashboard',
      },
      {
        title: 'Sensors',
        to: '',
        nestedElements: [
          {
            title: 'Floorcloud sensors',
            to: '/sensors',
          },
          {
            title: 'RapidRH sensors',
            to: '/wagner-probes',
          },
          {
            title: 'RapidRH adv packets decoder',
            to: '/sensors/advertisement-packet-decoder'
          }
        ]
      },
      {
        title: 'Contractors',
        to: '',
        nestedElements: [
          {
            title: 'Contractors',
            to: '/contractors',
          },
          {
            title: 'Users',
            to: '/users',
          },
        ]
      },
      {
        title: 'Projects',
        to: '',
        nestedElements: [
          {
            title: 'Projects',
            to: '/projects',
          },
          {
            title: 'Reports',
            to: '/reports',
          },
          {
            title: 'Events',
            to: '/events',
          },
          {
            title: 'Tasks',
            to: '/tasks',
          },
        ]
      },
      {
        title: 'Manufacturers',
        to: '/manufacturers',
      },
      {
        title: 'Products',
        to: '',
        badgeContent: getBudgeContent('productRequest'),
        nestedElements: [
          {
            title: 'Published products',
            to: '/products',
          },
          {
            title: 'DB management',
            to: '/db-management',
          },
          {
            title: 'Product requests',
            to: '/product-requests',
            badgeContent: getBudgeContent('productRequest'),
          },
          {
            title: 'Import history',
            to: '/product-imports',
          },
        ]
      },
      {
        title: 'GangBox',
        to: '',
        nestedElements: [
          {
            title: 'Feed',
            to: '/manufacturer-announcements',
          },
          {
            title: 'Settings',
            to: '/manufacturer-announcements/settings',
          }
        ]
      },
      {
        title: 'Messages',
        to: '',
        nestedElements: [
          {
            title: 'Send a new message',
            to: '/messages/new',
          },
          {
            title: 'History',
            to: '/messages/history',
          },
        ]
      },
    ],
    secondary: [
      {
        title: 'Release info',
        to: '/releases',
      },
      {
        title: 'Diagnostics',
        to: '/diagnostics',
      },
      {
        title: 'Settings',
        to: '/settings',
      },
      {
        title: 'My profile',
        to: '/profile',
      },
    ]
  }
)

export const Sidebar: FC = () => {
  const navigate = useNavigate()
  const signOut = useSignOut()
  const goTo = (path: string) => {
    navigate(path)
  }
  const { newProductRequestNumber } = useNewProductRequestNumber()
  const getBadgeContent = useCallback((menuItem: MenuItemType) => {
    if (menuItem === 'productRequest') return newProductRequestNumber
    return 0
  }, [newProductRequestNumber])
  const { primary: primaryLinks, secondary: secondaryLinks }: GetLinksResult = useCallback(
    () => getLinks(getBadgeContent), [getBadgeContent]
  )()
  const getStandMeta = useCallback(() => {
    const stand: Record<Stand, { name: string, titleColor: string, sidebarColor: string }> = {
      dev: {
        name: 'Develop',
        titleColor: 'rgba(0, 0, 0, 0.12)',
        sidebarColor: '#4D5F7A',
      },
      staging: {
        name: 'Staging',
        titleColor: 'rgba(0, 0, 0, 0.12)',
        sidebarColor: '#4D5F7A',
      },
      production: {
        name: 'Production',
        titleColor: '#F04848',
        sidebarColor: 'primary.main'
      }
    }
    return stand[config.stand] || {
      ...stand.production,
      name: 'Stand is not defined',
    }
  }, [])()

  return (
    <ContentBox
      width="245px"
      height="100vh"
      px={4}
      pb={2}
      sx={{ bgcolor: getStandMeta.sidebarColor, position: 'relative' }}
    >
      <Box p={1} mb={1} width="100%" sx={{ position: 'absolute', top: 0, left: 0, backgroundColor: getStandMeta.titleColor }}>
        <Typography
          variant='body2'
          textAlign='center'
          color='white'
          fontWeight={500}
          textTransform='uppercase'
        >
          {getStandMeta.name}
        </Typography>
      </Box>
      <Grid
        container
        direction="column"
        flexWrap="nowrap"
        sx={{ position: 'relative', height: '100%', boxSizing: 'border-box' }}
      >
        <Grid item sx={{ mt: 1 }} flexBasis={0}>
          <LogoHorizontalWhite />
        </Grid>
        <Grid item sx={{ mt: 1, mb: 2 }} flexBasis={0}>
          <Divider sx={{ borderColor: 'common.white', opacity: '0.2' }} />
        </Grid>
        <Grid
          item
          container
          flexDirection="column"
          flexGrow={1}
          flexBasis="95%"
          sx={{ overflowY: "auto" }}
          flexWrap="nowrap"
        >
          <Grid item><List>{primaryLinks.map((link, idx) => BuildLinkList({ link, idx }))}</List></Grid>
          <Grid item sx={{ mt: 1, mb: 1 }}>
            <Divider sx={{ borderColor: 'common.white', opacity: '0.2' }} />
          </Grid>
          <Grid item><List>{secondaryLinks.map((link, idx) => BuildLinkList({ link, idx }))}</List></Grid>
        </Grid>
        <Grid
          item
          container
          sx={{ mb: 2, mx: -2, position: 'sticky', bottom: 16, top: 500 }}
          direction="column"
        >
          <Grid item container alignItems="center">
            <Grid item>
              <IconButton role="link" size="large" onClick={signOut}>
                <Logout sx={{ color: 'common.white' }} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                role="link"
                size="large"
                onClick={() => goTo('/settings?tab=EventTypes')}
              >
                <Settings sx={{ color: 'common.white' }} />
              </IconButton>
            </Grid>
            <Grid item flexGrow={1} textAlign="end">
              <Typography variant="body2" color="darkgray">v {packageJson.version} ({config.buildVersion})</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ContentBox>
  )
}
