import React, { FC } from 'react'
import {
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  CircularProgress,
  DialogContentText,
} from '@mui/material'
import { StatefulDialog } from 'src/components'
import { DialogNames } from 'src/enums'
import { BleManageState } from 'src/interfaces'

export const ManageSensorBleModal: FC<{
  onSubmit: (state: BleManageState) => void
  onClose: () => void
  bleState: BleManageState
  deviceId: string
  isLoading?: boolean
}> = ({ onSubmit, onClose, isLoading, bleState, deviceId }) => {
  const dialogName = DialogNames.ManageSensorBle
  const handleClose = () => onClose()

  return (
    <StatefulDialog
      name={dialogName}
      onClose={handleClose}
      actions={
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            Close
          </Button>
          <Button
            onClick={() => onSubmit(bleState)}
            color="error"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={22} /> : bleState === BleManageState.ON ? 'Enable' : 'Disable'}
          </Button>
        </DialogActions>
      }
    >
      <DialogTitle id="alert-dialog-title">
        Manage sensor BLE
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Are you sure you want to <strong>{bleState === BleManageState.ON ? 'enable' : 'disable'}</strong> BLE for the GAT sensor with IMEI <strong>{deviceId}</strong>?
        </DialogContentText>
      </DialogContent>
    </StatefulDialog>
  )
}
