import React, { FC, useCallback, useContext, useMemo } from "react"
import * as Yup from "yup"
import {
  Button,
  Grid,
  DialogActions,
  DialogTitle,
  Typography,
} from "@mui/material"
import { useFormik, FormikContext, Form, FormikHelpers } from "formik"
import {
  TextField,
  FormDropdown,
  NumberTextField,
  Dialog,
} from "src/components/ui"
import { useDropdownItemFactory } from "src/hooks/ui"
import {
  ProductTipCreation,
  ProductTipEditing,
  ProductDraftSafetyTipTypeEnum,
  ProductDraftCommonTipTypeEnum,
  ProductsDraftsUpdate,
} from "src/api"
import {
  commonTipOptions,
  convertProductTipsToEditing,
  safetyTipOptions,
} from "src/utils/tips"
import pluralize from "pluralize"
import { ProductTipForm } from "./productTipForm"
import { AdditionalFields } from "./additionalFields"
import { ProductDraftsContext } from "../../../contexts/productDraftsContext"

const KEEP_CURRENT_OPTION_ID = "keep current"

const withKeepCurrentOption = (options: any[]) => [
  { id: KEEP_CURRENT_OPTION_ID, name: "Keep current" },
  ...options,
]

const validationSchema = Yup.object().shape(
  {
    typeId: Yup.lazy((value) =>
      value === KEEP_CURRENT_OPTION_ID ? Yup.string() : Yup.number()
    ),
    shelfLife: Yup.number()
      .nullable()
      .integer("Shelf Life must be a positive number")
      .positive("Shelf Life must be a positive number"),
    temperatureFrom: Yup.number()
      .integer()
      .nullable()
      .when("temperatureTo", (temperatureTo, schema) =>
        Number.isNaN(parseInt(temperatureTo, 10))
          ? schema
          : schema.max(temperatureTo, "Ambient Min Install Temp should be less than Max Install Temp")
      ),
    temperatureTo: Yup.number()
      .integer()
      .nullable()
      .when("temperatureFrom", (temperatureFrom, schema) =>
        Number.isNaN(parseInt(temperatureFrom, 10))
          ? schema
          : schema.min(
            temperatureFrom,
            "Ambient Max Install Temp should be greater than Min Install Temp"
          )
      ),
    humidityFrom: Yup.number()
      .integer()
      .nullable()
      .moreThan(0, "RH% must be greater than 0")
      .lessThan(101, "RH% must be less or equal 100")
      .when("humidityTo", (humidityTo, schema) =>
        Number.isNaN(parseInt(humidityTo, 10))
          ? schema
          : schema.max(
            humidityTo,
            "Ambient RH% Min should be less than Ambient RH% Max"
          )
      ),
    humidityTo: Yup.number()
      .integer()
      .nullable()
      .moreThan(0, "RH% must be greater than 0")
      .lessThan(101, "RH% must be less or equal 100")
      .when("humidityFrom", (humidityFrom, schema) =>
        Number.isNaN(parseInt(humidityFrom, 10))
          ? schema
          : schema.min(
            humidityFrom,
            "Ambient RH% Max should be greater than Ambient RH% Min"
          )
      ),
    lowSlabRH: Yup.number()
      .integer()
      .nullable()
      .moreThan(0, "RH% must be greater than 0")
      .lessThan(101, "RH% must be less or equal 100")
      .when("highSlabRH", (highSlabRH, schema) =>
        Number.isNaN(parseInt(highSlabRH, 10))
          ? schema
          : schema.max(
            highSlabRH,
            "Slab RH% Min should be less than Slab RH% Max"
          )
      ),
    highSlabRH: Yup.number()
      .integer()
      .nullable()
      .moreThan(0, "RH% must be greater than 0")
      .lessThan(101, "RH% must be less or equal 100")
      .when("lowSlabRH", (lowSlabRH, schema) =>
        Number.isNaN(parseInt(lowSlabRH, 10))
          ? schema
          : schema.min(
            lowSlabRH,
            "Slab RH% Max should be greater than Slab RH% Min"
          )
      ),
    lowSurfaceTempF: Yup.number()
      .integer()
      .nullable()
      .when("highSurfaceTempF", (highSurfaceTempF, schema) =>
        Number.isNaN(parseInt(highSurfaceTempF, 10))
          ? schema
          : schema.max(
            highSurfaceTempF,
            "Surface Temp Min should be less than Surface Temp Max"
          )
      ),
    highSurfaceTempF: Yup.number()
      .integer()
      .nullable()
      .when("lowSurfaceTempF", (lowSurfaceTempF, schema) =>
        Number.isNaN(parseInt(lowSurfaceTempF, 10))
          ? schema
          : schema.min(
            lowSurfaceTempF,
            "Surface Temp Max should be greater than Surface Temp Min"
          )
      ),
    lowSlabPH: Yup.number()
      .integer()
      .nullable()
      .when("highSlabPH", (highSlabPH, schema) =>
        Number.isNaN(parseInt(highSlabPH, 10))
          ? schema
          : schema.max(
            highSlabPH,
            "pH Min should be less than pH Max"
          )
      ),
    highSlabPH: Yup.number()
      .integer()
      .nullable()
      .when("lowSlabPH", (lowSlabPH, schema) =>
        Number.isNaN(parseInt(lowSlabPH, 10))
          ? schema
          : schema.min(
            lowSlabPH,
            "pH Max should be greater than pH Min"
          )
      ),
    boardWidth: Yup.number()
      .nullable()
      .positive("Board Width must be a positive number"),
    highSlabMVER: Yup.number()
      .nullable()
      .integer("Slab MVER (CaCl) Max must be a positive number")
      .positive("Slab MVER (CaCl) Max must be a positive number"),
    commonTipList: Yup.array().of(
      Yup.object().shape({
        content: Yup.string().required("Tip field should be filled"),
      })
    ),
    safetyTipList: Yup.array().of(
      Yup.object().shape({
        content: Yup.string().required("Safety tip field should be filled"),
      })
    ),
    newInstallationTip: Yup.string(),
    newSafetyTip: Yup.string(),
  },
  [
    ["humidityTo", "humidityFrom"],
    ["temperatureFrom", "temperatureTo"],
    ["lowSlabRH", "highSlabRH"],
    ["lowSurfaceTempF", "highSurfaceTempF"],
    ["lowSlabPH", "highSlabPH"],
  ]
)

type InnerProductTypeId = number | typeof KEEP_CURRENT_OPTION_ID;

interface TechProductDraftMassEditState {
  typeId: InnerProductTypeId;
  shelfLife?: number;
  temperatureFrom?: number;
  temperatureTo?: number;
  humidityFrom?: number;
  humidityTo?: number;
  highSlabMVER?: number;
  lowSlabRH?: number;
  highSlabRH?: number;
  lowSurfaceTempF?: number;
  highSurfaceTempF?: number;
  lowSlabPH?: number;
  highSlabPH?: number;
  boardWidth?: number;
  commonTipList: ProductTipCreation[] | ProductTipEditing[];
  safetyTipList: ProductTipCreation[] | ProductTipEditing[];
  commonNewTip?: string;
  safetyNewTip?: string;
  commonTipType: ProductDraftCommonTipTypeEnum | typeof KEEP_CURRENT_OPTION_ID;
  safetyTipType: ProductDraftSafetyTipTypeEnum | typeof KEEP_CURRENT_OPTION_ID;
}

const initialValues: TechProductDraftMassEditState = {
  typeId: KEEP_CURRENT_OPTION_ID,
  shelfLife: void 0,
  temperatureFrom: void 0,
  temperatureTo: void 0,
  humidityFrom: void 0,
  humidityTo: void 0,
  highSlabMVER: void 0,
  lowSlabRH: void 0,
  highSlabRH: void 0,
  lowSurfaceTempF: void 0,
  highSurfaceTempF: void 0,
  lowSlabPH: void 0,
  highSlabPH: void 0,
  boardWidth: void 0,
  commonTipType: KEEP_CURRENT_OPTION_ID,
  safetyTipType: KEEP_CURRENT_OPTION_ID,
  commonNewTip: "",
  safetyNewTip: "",
  commonTipList: [],
  safetyTipList: [],
}

export type ProductDraftMassEditState = Omit<ProductsDraftsUpdate, "id">;

interface ProductDraftMassEditFormProps {
  open: boolean;
  onSave: (formState: ProductDraftMassEditState) => void;
  onCancel: () => void;
}

export const ProductDraftMassEditDialogForm: FC<ProductDraftMassEditFormProps> =
  ({ open, onSave, onCancel }) => {
    const {
      commonTipTypeId,
      safetyTipTypeId,
      selectedProductDraftIds,
      productTypes,
      defaultTips,
    } = useContext(ProductDraftsContext)

    const dropdownItemListMap = useDropdownItemFactory({
      params: { labelProp: "name", valueProp: "id" },
    })

    const onSubmit = (
      data: TechProductDraftMassEditState,
      formikHelper: FormikHelpers<TechProductDraftMassEditState>
    ) => {
      const commonTipType =
        data.commonTipType === KEEP_CURRENT_OPTION_ID
          ? undefined
          : data.commonTipType

      const safetyTipType =
        data.safetyTipType === KEEP_CURRENT_OPTION_ID
          ? undefined
          : data.safetyTipType

      let commonCustomTips
      if (commonTipType === ProductDraftCommonTipTypeEnum.Custom) {
        commonCustomTips = data.commonTipList
      } else if (commonTipType === ProductDraftCommonTipTypeEnum.Default) {
        commonCustomTips = []
      }

      let safetyCustomTips
      if (safetyTipType === ProductDraftSafetyTipTypeEnum.Custom) {
        safetyCustomTips = data.safetyTipList
      } else if (safetyTipType === ProductDraftSafetyTipTypeEnum.Default) {
        safetyCustomTips = []
      }

      onSave({
        typeId:
          data.typeId === KEEP_CURRENT_OPTION_ID ? undefined : data.typeId,
        shelfLife: data.shelfLife,
        lowTempF: data.temperatureFrom,
        highTempF: data.temperatureTo,
        lowHumidity: data.humidityFrom,
        highHumidity: data.humidityTo,
        highSlabMVER: data.highSlabMVER,
        lowSlabRH: data.lowSlabRH,
        highSlabRH: data.highSlabRH,
        lowSurfaceTempF: data.lowSurfaceTempF,
        highSurfaceTempF: data.highSurfaceTempF,
        lowSlabPH: data.lowSlabPH,
        highSlabPH: data.highSlabPH,
        boardWidth: data.boardWidth,
        commonTipType,
        safetyTipType,
        commonCustomTips,
        safetyCustomTips,
      })

      formikHelper.resetForm()
    }

    const formik = useFormik({
      validationSchema: validationSchema,
      initialValues,
      onSubmit,
      validateOnChange: false,
      validateOnBlur: true,
    })

    const {
      values: formValues,
      errors,
      setFieldValue,
      submitForm,
      resetForm,
    } = formik

    const getDefaultTipListByType = useCallback(
      (productTypeId?: InnerProductTypeId, typeId?: number) =>
        defaultTips.filter(
          (tip) => tip.typeId === typeId && tip.productTypeId === productTypeId
        ),
      [defaultTips]
    )

    const commonDefaultTipList = useMemo(
      () => getDefaultTipListByType(formValues.typeId, commonTipTypeId),
      [getDefaultTipListByType, commonTipTypeId, formValues.typeId]
    )

    const safetyDefaultTipList = useMemo(
      () => getDefaultTipListByType(formValues.typeId, safetyTipTypeId),
      [getDefaultTipListByType, safetyTipTypeId, formValues.typeId]
    )

    const handleChangeProductType = (productTypeId: number) => {
      const newCommonTips = getDefaultTipListByType(
        productTypeId,
        commonTipTypeId
      )
      const newSafetyTips = getDefaultTipListByType(
        productTypeId,
        safetyTipTypeId
      )

      setFieldValue(
        "commonTipList",
        convertProductTipsToEditing(newCommonTips)
      )
      setFieldValue(
        "safetyTipList",
        convertProductTipsToEditing(newSafetyTips)
      )
    }

    const handleClose = () => {
      resetForm()
      onCancel()
    }

    return (
      <FormikContext.Provider value={formik}>
        <Form>
          <Dialog
            open={open}
            title={
              <DialogTitle>
                Set new parameter for{" "}
                {pluralize("product", selectedProductDraftIds.length, true)}
              </DialogTitle>
            }
            scroll="paper"
            actions={
              <DialogActions>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={Object.keys(errors).length > 0}
                  onClick={submitForm}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  type="button"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </DialogActions>
            }
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormDropdown
                  list={withKeepCurrentOption(productTypes)}
                  name="typeId"
                  label="Category*"
                  menuItemFactory={dropdownItemListMap}
                  onChange={([e]) => handleChangeProductType(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="shelfLife"
                  label="Shelf Life"
                  TextFieldProps={{
                    InputProps: {
                      inputComponent: NumberTextField as any,
                    },
                    placeholder: "Days",
                  }}
                />
              </Grid>
              <Grid item container spacing={2} xs={12}>
                <Grid item xs={6}>
                  <TextField
                    name="temperatureFrom"
                    label="Ambient Min Install Temp"
                    TextFieldProps={{
                      InputProps: {
                        inputComponent: NumberTextField as any,
                      },
                      placeholder: "°F",
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="temperatureTo"
                    label="Ambient Max Install Temp"
                    TextFieldProps={{
                      InputProps: {
                        inputComponent: NumberTextField as any,
                      },
                      placeholder: "°F",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2} xs={12}>
                <Grid item xs={6}>
                  <TextField
                    name="humidityFrom"
                    label="Ambient RH% Min"
                    TextFieldProps={{
                      InputProps: {
                        inputComponent: NumberTextField as any,
                      },
                      placeholder: "%",
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="humidityTo"
                    label="Ambient RH% Max"
                    TextFieldProps={{
                      InputProps: {
                        inputComponent: NumberTextField as any,
                      },
                      placeholder: "%",
                    }}
                  />
                </Grid>
              </Grid>
              {commonTipTypeId && (
                <Grid item xs={12}>
                  <ProductTipForm
                    type="common"
                    typeId={commonTipTypeId}
                    typeOptions={withKeepCurrentOption(commonTipOptions)}
                    label="Installation Tips"
                    defaultTipList={commonDefaultTipList}
                    checkIfEditable={(v) =>
                      v === ProductDraftCommonTipTypeEnum.Custom
                    }
                    checkIfDefault={(v) =>
                      v === ProductDraftCommonTipTypeEnum.Default
                    }
                  />
                </Grid>
              )}
              {safetyTipTypeId && (
                <Grid item xs={12}>
                  <ProductTipForm
                    type="safety"
                    typeId={safetyTipTypeId}
                    typeOptions={withKeepCurrentOption(safetyTipOptions)}
                    label="Safety Tips"
                    defaultTipList={safetyDefaultTipList}
                    checkIfEditable={(v) =>
                      v === ProductDraftSafetyTipTypeEnum.Custom
                    }
                    checkIfDefault={(v) =>
                      v === ProductDraftSafetyTipTypeEnum.Default
                    }
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <AdditionalFields />
              </Grid>
            </Grid>
            <Grid item xs={12} mt={4}>
              <Typography color="error" align="center" variant="body2">
                Please note, tips are published to DB immediately
              </Typography>
            </Grid>
          </Dialog>
        </Form>
      </FormikContext.Provider>
    )
  }
