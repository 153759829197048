import { FC, PropsWithChildren } from "react"
import { Grid } from "@mui/material"
import {
  HasSidebarLayout,
  DataLoad,
  ListHeader,
  PropsInjector,
  ReportList,
} from "src/components"
import { useReportListRequest } from "src/hooks/api"
import { useQueryParams, useQueryParamsState } from "src/hooks/ui"
import { Report } from "src/api"
import { defaultRowsValuePerPage } from "src/config"

interface ReportsViewProps {
  reports?: Report[];
  reportsCount?: number;
  reportsLoading?: boolean;
  isLoading?: boolean;
  queryParams?: Record<string, string>;
  setQueryParams?: (params: Record<string, string>, paramsToRemove?: string[]) => void;
  defaultRowsPerPageOptions?: number[]
}

const ReportsView: FC<ReportsViewProps> = ({
  reports = [],
  reportsCount = 0,
  isLoading = false,
  setQueryParams,
  queryParams,
  defaultRowsPerPageOptions
}) => {
  return (
    <>
      <Grid container direction="column" alignItems="stretch">
        <ListHeader title="Reports" />
      </Grid>
      <ReportList
        isLoading={isLoading}
        data={reports}
        count={reportsCount}
        setQueryParams={setQueryParams}
        queryParams={queryParams}
        defaultRowsPerPageOptions={defaultRowsPerPageOptions}
      />
    </>
  )
}

const defaultPerPage = 25
const defaultPage = 1
const defaultRowsPerPageOptions = defaultRowsValuePerPage
const initialQueryParams = {
  page: `${defaultPage}`,
  rowsPerPage: `${defaultPerPage}`,
}

const ReportDataLoader: FC<PropsWithChildren> = ({ children }) => {
  const params = useQueryParamsState()
  const { data, isInitialLoading, isError, error } = useReportListRequest({
    params,
  })
  const [queryParams, setQueryParams] = useQueryParams(initialQueryParams)

  const props: ReportsViewProps = {
    reports: data?.rows || [],
    reportsCount: data?.count || 0,
    reportsLoading: isInitialLoading,
    isLoading: isInitialLoading,
    queryParams,
    setQueryParams,
    defaultRowsPerPageOptions
  }

  return (
    <DataLoad isLoading={false} isError={isError} errorMessage={error?.message}>
      <PropsInjector props={props}>{children}</PropsInjector>
    </DataLoad>
  )
}

const Reports: FC = () => {
  document.title = 'Floorcloud Admin Panel - Reports'
  return (
    <HasSidebarLayout>
      <ReportDataLoader>
        <ReportsView />
      </ReportDataLoader>
    </HasSidebarLayout>
  )
}

export default Reports
