import React, { FC } from "react"
import { Grid, Typography, Button } from "@mui/material"
import { Link } from "react-router-dom"
import { Release } from "src/api"
import { ReleaseFeatureCard } from "./releaseFeatureCard"

interface ReleaseCardProps {
  release: Release;
}

export const ReleaseCard: FC<ReleaseCardProps> = ({
  release
}) => {
  return (
    <Grid container direction="column" alignItems="stretch" px={5} py={4} border={1} borderColor="gray">
      <Grid item container justifyContent="space-between" sx={{ mb: 2 }} xs>
        <Grid item>
          <Typography variant="h6" fontWeight={500}>{release.description}</Typography>
        </Grid>
        <Grid item>
          <Link to={`/releases/${release.id}/edit`} style={{ textDecoration: "none" }}>
            <Button role="link" variant="outlined">
              Edit
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid item>
        {release.features.map((feature) => (
          <ReleaseFeatureCard feature={feature} key={feature.id} />
        ))}
      </Grid>
    </Grid>
  )
}
