import { Fragment } from "react"
import distanceInWords from "date-fns/formatDistance"
import format from "date-fns/format"
import { Grid, TableCell, Typography } from "@mui/material"
import { GridCellParams, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid-premium'
import { FirmwareHistoryItem, ProjectSectionSensor, Sensor, SensorBackupUplinkItem, SensorTechnicalStatusEnum, ThingspaceSensor, UnstableSensor } from "src/api"
import { DynamicColumn, InnerLink, OuterLink } from "src/components/ui"
import { ReadingType, SensorStateList } from "src/enums"
import { getBleDataModeName, getOpModeName, getReportTypeName } from "./helpers"
import { Measurment, Reading, SensorArchivedLabel, SensorStagingLabel } from "../sections"

namespace SensorColumns {
  export const Id: DynamicColumn<Sensor> = {
    field: "uniqueId",
    headerName: "ID",
    type: "string",
    sort: true,
    innerLink: ({ uniqueId }) => `/sensors/${uniqueId}`,
  }

  export const Name: DynamicColumn<Sensor> = {
    field: "name",
    headerName: "Name",
    type: "string",
    sort: true,
  }

  export const Model: DynamicColumn<Sensor> = {
    field: "sensorModel.displayName",
    headerName: "Model",
    type: "string",
  }

  export const LastPing: DynamicColumn<Sensor> = {
    field: "currentData.updatedAt",
    headerName: "Last Ping",
    type: "date",
    format: (value) => distanceInWords(new Date(value), new Date()),
  }

  export const FirmwareVersion: DynamicColumn<Sensor> = {
    field: "firmware",
    headerName: "Firmware Version",
    type: "string",
  }

  export const SignalStrength: DynamicColumn<Sensor> = {
    field: "currentData.signalStrength",
    headerName: "Signal Strength",
    type: "string",
  }

  export const BatteryLevel: DynamicColumn<Sensor> = {
    field: "currentData.batteryLevel",
    headerName: "Battery Level",
    type: "custom",
    component: ({ row, cellProps }) => {
      const value = row?.currentData?.batteryLevel
        ? `${row.currentData.batteryLevel}%`
        : "-"
      return <TableCell align={cellProps?.align}>{value}</TableCell>
    },
  }

  export const Status: DynamicColumn<Sensor> = {
    field: "status",
    headerName: "Status",
    type: "custom",
    component: ({ row, cellProps }) => {
      const value = row.isOnline ? "Connected" : "Awaiting response"
      return <TableCell align={cellProps?.align}>{value}</TableCell>
    },
  }

  export const State: DynamicColumn<Sensor> = {
    field: "state",
    headerName: "Status",
    type: "custom",
    component: ({ row, cellProps }) => {
      const value = row.state?.name
      return <TableCell align={cellProps?.align}>{value}</TableCell>
    },
  }

  export const DateAdded: DynamicColumn<Sensor> = {
    field: "currentData.createdAt",
    headerName: "Date Added",
    type: "date",
    format: (value) => format(new Date(value), "P"),
  }

  export const Description: DynamicColumn<Sensor> = {
    field: "description",
    headerName: "Description",
    type: "string",
  }

  export const DateAssigned: DynamicColumn<Sensor> = {
    field: "contractorSensors.0.createdAt",
    headerName: "Date Assigned",
    type: "date",
    format: (value) => format(new Date(value), "P"),
  }

  export const Contractor: DynamicColumn<Sensor> = {
    field: "contractorSensors.0.contractor.name",
    headerName: "Contractor",
    type: "custom",
    component: ({ row, cellProps }) => {
      const { contractorSensors } = row
      return (
        <TableCell align={cellProps?.align}>
          {contractorSensors?.length
            ? contractorSensors.map((el, idx, arr) => (
              <Fragment key={el.id}>
                <InnerLink to={`/contractors/${el.contractorId}`}>
                  {el?.contractor?.name}
                </InnerLink>
                {idx < arr.length - 1 && <br />}
              </Fragment>
            ))
            : "-"}
        </TableCell>
      )
    },
  }

  export const DateConnected: DynamicColumn<Sensor> = {
    field: "projectSectionsSensors.0.createdAt",
    headerName: "Date Connected",
    type: "date",
    format: (value) => format(new Date(value), "P"),
  }

  export const TimeOnProject: DynamicColumn<Sensor> = {
    field: "timeOnProject",
    headerName: "Time on a project",
    type: "custom",
    component: ({ row, cellProps }) => {
      const [section] = (row || {}).projectSectionsSensors || []
      let value = "-"
      if (section) {
        const createdAt = new Date(section.createdAt)
        const diff = Date.now() - createdAt.getTime()
        const count = Math.round(diff / 60 / 60 / 60 / 1000)
        value = `${count} d`
      }
      return <TableCell align={cellProps?.align}>{value}</TableCell>
    },
  }

  export const UsedAt: DynamicColumn<Sensor> = {
    field: "projectSectionsSensors.0.project.fullAddress",
    headerName: "Used at",
    type: "string",
  }

  export const Project: DynamicColumn<Sensor> = {
    field: "projectSectionsSensors.0.project.name",
    headerName: "Project",
    type: "string",
    innerLink: (row) => {
      if (row?.projectSectionsSensors?.length) {
        return `/projects/${
          (row?.projectSectionsSensors as ProjectSectionSensor[])[0]?.projectId
        }`
      }
      return "-"
    },
  }

  export const TypeName: DynamicColumn<Sensor> = {
    field: "type.displayName",
    headerName: "Type",
    type: "string",
  }

  export const IMEI: DynamicColumn<Sensor> = {
    field: "imei",
    headerName: "IMEI",
    type: "string",
    sort: true,
  }

  export const Comment: DynamicColumn<Sensor> = {
    field: "comment",
    headerName: "Internal comment",
    type: "string",
    sort: false,
  }

  export const EnabledBluetooth: DynamicColumn<Sensor> = {
    field: "isEnabledBluetooth",
    headerName: "Bluetooth",
    type: "custom",
    sort: false,
    component: ({ row, cellProps }) => {
      const { isEnabledBluetooth } = row
      let value = "-"
      if (isEnabledBluetooth === true) value = "Enabled"
      if (isEnabledBluetooth === false) value = "Disabled"
      return <TableCell align={cellProps?.align}>{value}</TableCell>
    },
  }

  export const DevEUI: DynamicColumn<Sensor> = {
    field: "devEUI",
    headerName: "Dev EUI",
    type: "string",
  }

  export const ApplicationEUI: DynamicColumn<Sensor> = {
    field: "applicationEUI",
    headerName: "Application EUI",
    type: "string",
  }

  export const ApplicationKey: DynamicColumn<Sensor> = {
    field: "applicationKey",
    headerName: "Application Key",
    type: "string",
  }

  export const MaxAwakeDuration: DynamicColumn<Sensor> = {
    field: "currentConfig.commMaxAwakeDuration",
    headerName: "Maximum Awake Duration",
    type: "digit",
  }

  export const MinAwakeDuration: DynamicColumn<Sensor> = {
    field: "currentConfig.commMinAwakeDuration",
    headerName: "Minimum Awake Duration",
    type: "digit",
  }

  export const PeriodSet: DynamicColumn<Sensor> = {
    field: "currentConfig.commPeriodSet",
    headerName: "Period Set, min (sec)",
    type: "custom",
    cellProps: { align: "right" },
    component: ({ row, cellProps }) => {
      const { currentConfig } = row
      let value = "-"
      if (currentConfig && currentConfig.commPeriodSet) {
        const { commPeriodSet: sec } = currentConfig
        const min = (sec / 60).toFixed(0)
        value = `${min} (${sec})`
      }
      return <TableCell align={cellProps?.align}>{value}</TableCell>
    },
  }

  export const SynchPeriod: DynamicColumn<Sensor> = {
    field: "currentConfig.commSynchPeriod",
    headerName: "Synchronization Period, min (sec)",
    type: "custom",
    cellProps: { align: "right" },
    component: ({ row, cellProps }) => {
      const { currentConfig } = row
      let value = "-"
      if (currentConfig && currentConfig.commPeriodSet) {
        const { commPeriodSet: sec } = currentConfig
        const min = (sec / 60).toFixed(0)
        value = `${min} (${sec})`
      }
      return <TableCell align={cellProps?.align}>{value}</TableCell>
    },
  }

  export const ConfigType: DynamicColumn<Sensor> = {
    field: "currentConfig.commType",
    headerName: "Type",
    type: "string",
  }

  export const ConfigTypeUpdate: DynamicColumn<Sensor> = {
    field: "currentConfig.commTypeUpdate",
    headerName: "Type Update",
    type: "string",
  }

  export const ConfigLastUpdate: DynamicColumn<Sensor> = {
    field: "currentData.configurationTime",
    headerName: "Last Received",
    type: "date",
    format: (value) => format(new Date(value), "PPpp"),
  }

  export const ConfigBleMonitorPeriod: DynamicColumn<Sensor> = {
    field: "currentConfig.bleMonitorPeriod",
    headerName: "Monitor period, min (sec)",
    type: "custom",
    cellProps: { align: "left" },
    component: ({ row, cellProps }) => {
      const { currentConfig } = row
      let value = "-"
      if (currentConfig && currentConfig.bleMonitorPeriod) {
        const { bleMonitorPeriod: sec } = currentConfig
        const min = (sec / 60).toFixed(0)
        value = `${min} (${sec})`
      }
      return <TableCell align={cellProps?.align}>{value}</TableCell>
    },
  }

  export const ConfigBleReportPeriod: DynamicColumn<Sensor> = {
    field: "currentConfig.bleReportPeriod",
    headerName: "Report period, min (sec)",
    type: "custom",
    cellProps: { align: "left" },
    component: ({ row, cellProps }) => {
      const { currentConfig } = row
      let value = "-"
      if (currentConfig && currentConfig.bleReportPeriod) {
        const { bleReportPeriod: sec } = currentConfig
        const min = (sec / 60).toFixed(0)
        value = `${min} (${sec})`
      }
      return <TableCell align={cellProps?.align}>{value}</TableCell>
    },
  }

  export const ConfigBleReportOffset: DynamicColumn<Sensor> = {
    field: "currentConfig.bleReportOffset",
    headerName: "Report offset, min (sec)",
    type: "custom",
    cellProps: { align: "left" },
    component: ({ row, cellProps }) => {
      const { currentConfig } = row
      let value = "-"
      if (currentConfig && currentConfig.bleReportOffset !== undefined && currentConfig.bleReportOffset !== null) {
        const { bleReportOffset: sec } = currentConfig
        const min = (sec / 60).toFixed(0)
        value = `${min} (${sec})`
      }
      return <TableCell align={cellProps?.align}>{value}</TableCell>
    },
  }

  export const ConfigBleOpMode: DynamicColumn<Sensor> = {
    field: "currentConfig.bleOpMode",
    headerName: "Operation mode",
    type: "custom",
    cellProps: { align: "left" },
    component: ({ row, cellProps }) => {
      const { currentConfig } = row
      let value = getOpModeName(currentConfig?.bleOpMode)
      return <TableCell align={cellProps?.align}>{value}</TableCell>
    },
  }

  export const ConfigBleReportType: DynamicColumn<Sensor> = {
    field: "currentConfig.bleReportType",
    headerName: "Report type",
    type: "custom",
    cellProps: { align: "left" },
    component: ({ row, cellProps }) => {
      const { currentConfig } = row
      let value = getReportTypeName(currentConfig?.bleReportType)
      return <TableCell align={cellProps?.align}>{value}</TableCell>
    },
  }

  export const ConfigBleScanDuration: DynamicColumn<Sensor> = {
    field: "currentConfig.bleScanDuration",
    headerName: "Scan duration",
    type: "digit",
    cellProps: { align: "left" },
  }

  export const ConfigBleMinSigStrength: DynamicColumn<Sensor> = {
    field: "currentConfig.bleMinSigStr",
    headerName: "Minimal signal strength",
    type: "digit",
    cellProps: { align: "left" },
  }

  export const ConfigBleDataMode: DynamicColumn<Sensor> = {
    field: "currentConfig.bleDataMode",
    headerName: "Data mode",
    type: "custom",
    cellProps: { align: "left" },
    component: ({ row, cellProps }) => {
      const { currentConfig } = row
      let value = getBleDataModeName(currentConfig?.bleDataMode)
      return <TableCell align={cellProps?.align}>{value}</TableCell>
    },
  }

  export const ConfigBleMaxNumScan: DynamicColumn<Sensor> = {
    field: "currentConfig.bleMaxNumScan",
    headerName: "Maximum number of scan",
    type: "digit",
    cellProps: { align: "left" },
  }

  export const ConfigBleManufacturerId: DynamicColumn<Sensor> = {
    field: "currentConfig.bleManufacturerId",
    headerName: "Manufacturer ID",
    type: "digit",
    cellProps: { align: "left" },
  }
}

export namespace ThingspaceSensorColumns {
  export const Id: GridColDef<ThingspaceSensor> = {
    field: "sensor.uniqueId",
    headerName: "ID",
    type: "string",
    minWidth: 120,
    editable: false,
    renderCell: ({ row }) => {
      const uniqueId = row.sensor?.uniqueId
      return <InnerLink to={`/sensors/${uniqueId}`}>{uniqueId}</InnerLink>
    },
  }

  export const Model: GridColDef<ThingspaceSensor> = {
    field: "sensor.sensorModel.displayName",
    headerName: "Model",
    type: "string",
    minWidth: 130,
    editable: false,
    valueGetter: ({ row }) => row.sensor?.sensorModel?.displayName || "-"
  }

  export const LastPing: GridColDef<ThingspaceSensor> = {
    field: "sensor.currentData.updatedAt",
    headerName: "Last Ping",
    type: "string",
    minWidth: 100,
    editable: false,
    valueGetter: ({ row }) => row.sensor?.currentData?.updatedAt || null,
    valueFormatter: ({ value }) => value ? distanceInWords(new Date(value), new Date()) : "-",
  }

  export const Status: GridColDef<ThingspaceSensor> = {
    field: "sensor.status",
    headerName: "Status",
    type: "string",
    minWidth: 100,
    editable: false,
    valueGetter: ({ row }) => row.sensor?.isOnline,
    valueFormatter: ({ value }) => value ? "Connected" : "Awaiting response",
  }

  export const IMEI: GridColDef<ThingspaceSensor> = {
    field: "imei",
    headerName: "IMEI",
    type: "string",
    minWidth: 150,
    editable: false,
    valueGetter: ({ row }) => row.sensor?.imei || "-"
  }

  export const BatteryLevel: GridColDef<ThingspaceSensor> = {
    field: "sensor.currentData.batteryLevel",
    headerName: "Battery Level",
    type: "string",
    minWidth: 110,
    editable: false,
    valueGetter: ({ row }) => row.sensor?.currentData?.batteryLevel || null,
    valueFormatter: ({ value }) => value ? `${value}%` : "-",
  }

  export const AvailableInSystem: GridColDef<ThingspaceSensor> = {
    field: "isAvailableInSystem",
    headerName: "Available in system",
    type: "string",
    minWidth: 110,
    editable: false,
    renderCell: ({ row }) => {
      return (
        <Typography
          color={row.isAvailableInSystem ? 'inherit' : 'error'}
          variant="body2"
        >
          {row.isAvailableInSystem ? 'Available' : 'Not available'}
        </Typography>
      )
    },
  }

  export const ActivationStatus: GridColDef<ThingspaceSensor> = {
    field: "activationStatus",
    headerName: "Activation Status",
    type: "string",
    minWidth: 100,
    editable: false,
  }

  export const SIMOTADate: GridColDef<ThingspaceSensor> = {
    field: "simOTADate",
    headerName: "OTA",
    type: "string",
    minWidth: 110,
    editable: false,
    valueFormatter: ({ value }) => value ? format(new Date(value), "PPpp") : "-",
  }

  export const ServicePlan: GridColDef<ThingspaceSensor> = {
    field: "servicePlan",
    headerName: "Service plan",
    type: "string",
    minWidth: 110,
    editable: false,
  }

  export const CreatedAtInThingspace: GridColDef<ThingspaceSensor> = {
    field: "createdAtInThingspace",
    headerName: "Created At",
    type: "string",
    minWidth: 150,
    editable: false,
    valueFormatter: ({ value }) => value ? format(new Date(value), "PPpp") : "-",
  }
}

export namespace SensorLastReadingsColumns {
  export const ReadingName: DynamicColumn<Reading> = {
    field: "name",
    headerName: "Reading",
    type: "string",
  }

  export const ReadingValue: DynamicColumn<Reading> = {
    field: "value",
    headerName: "Value",
    type: "string",
    outerLink: (reading) => {
      if (reading.type === ReadingType.LocationCellular || reading.type === ReadingType.LocationGps) {
        return encodeURI(`https://www.google.com/maps/search/?api=1&query=${reading.value}`)
      }
      return ''
    },
  }

  export const ReadingLastPing: DynamicColumn<Reading> = {
    field: "lastPing",
    headerName: "Last Ping",
    type: "date",
    format: (value) => (value ? format(new Date(value), "PPpp") : ""),
  }
}

export namespace SensorConfigurationMeasurments {
  export const MeasurmentName: DynamicColumn<Measurment> = {
    field: "name",
    headerName: "Measurment",
    type: "string",
  }

  export const MeasurmentMonitorPeriod: DynamicColumn<Measurment> = {
    field: "monitorPeriod",
    headerName: "Monitor period, min(sec)",
    type: "custom",
    component: ({ row, cellProps }) => {
      const { monitorPeriod } = row
      let value = "-"
      if (monitorPeriod !== undefined && monitorPeriod !== null) {
        const min = (monitorPeriod / 60).toFixed(0)
        value = `${min} (${monitorPeriod})`
      }
      return <TableCell align={cellProps?.align}>{value}</TableCell>
    },
  }

  export const MeasurmentOpMode: DynamicColumn<Measurment> = {
    field: "opMode",
    headerName: "Operation mode",
    type: "string",
  }

  export const MeasurmentReportOffset: DynamicColumn<Measurment> = {
    field: "reportOffset",
    headerName: "Report offset, min(sec)",
    type: "custom",
    component: ({ row, cellProps }) => {
      const { reportOffset } = row
      let value = "-"
      if (reportOffset !== undefined && reportOffset !== null) {
        const min = (reportOffset / 60).toFixed(0)
        value = `${min} (${reportOffset})`
      }
      return <TableCell align={cellProps?.align}>{value}</TableCell>
    },
  }

  export const MeasurmentReportPeriod: DynamicColumn<Measurment> = {
    field: "reportPeriod",
    headerName: "Report period, min(sec)",
    type: "custom",
    component: ({ row, cellProps }) => {
      const { reportPeriod } = row
      let value = "-"
      if (reportPeriod !== undefined && reportPeriod !== null) {
        const min = (reportPeriod / 60).toFixed(0)
        value = `${min} (${reportPeriod})`
      }
      return <TableCell align={cellProps?.align}>{value}</TableCell>
    },
  }

  export const MeasurmentReportType: DynamicColumn<Measurment> = {
    field: "reportType",
    headerName: "Report type",
    type: "string",
  }
}

export namespace SensorColumnsDataGrid {
  export const Id: GridColDef<Sensor> = {
    field: "uniqueId",
    headerName: "ID",
    type: "string",
    minWidth: 220,
    editable: false,
    renderCell: ({ value, row }) => {
      const isStagingSensor = row.isStagingSensor
      const isArchivedSensor = row.state?.name === SensorStateList.Archived
      const isShortLabel = isStagingSensor && isArchivedSensor
      return (
        <Grid container spacing={1} alignItems="center" flexWrap="nowrap" overflow="auto">
          <Grid item>
            <InnerLink to={`/sensors/${value}`}>{value}</InnerLink>
          </Grid>
          {isStagingSensor ? (
            <Grid item>
              <SensorStagingLabel isShortLabel={isShortLabel} />
            </Grid>
          ) : null}
          {isArchivedSensor ? (
            <Grid item>
              <SensorArchivedLabel isShortLabel={isShortLabel} />
            </Grid>
          ) : null}
        </Grid>
      )
    }
  }

  export const Name: GridColDef<Sensor> = {
    field: "name",
    headerName: "Name",
    type: "string",
    minWidth: 180,
    editable: false,
  }

  export const Model: GridColDef<Sensor> = {
    field: "sensorModel.displayName",
    headerName: "Model",
    type: "string",
    minWidth: 130,
    editable: false,
    valueGetter: ({ row }) => row.sensorModel?.displayName || "-"
  }

  export const LastPing: GridColDef<Sensor> = {
    field: "currentData.updatedAt",
    headerName: "Last Ping",
    type: "string",
    minWidth: 100,
    editable: false,
    valueGetter: ({ row }) => row.currentData?.updatedAt || null,
    valueFormatter: ({ value }) => value ? distanceInWords(new Date(value), new Date()) : "-",
  }

  export const FirmwareVersion: GridColDef<Sensor> = {
    field: "firmware",
    headerName: "Firmware Version",
    type: "string",
    minWidth: 180,
    editable: false,
  }

  export const SignalStrength: GridColDef<Sensor> = {
    field: "currentData.signalStrength",
    headerName: "Signal Strength",
    type: "string",
    minWidth: 180,
    editable: false,
    valueGetter: ({ row }) => row.currentData?.signalStrength || null,
    valueFormatter: ({ value }) => value || "-",
  }

  export const BatteryLevel: GridColDef<Sensor> = {
    field: "currentData.batteryLevel",
    headerName: "Battery Level",
    type: "string",
    minWidth: 110,
    editable: false,
    valueGetter: ({ row }) => row.currentData?.batteryLevel || null,
    valueFormatter: ({ value }) => value ? `${value}%` : "-",
  }

  export const Status: GridColDef<Sensor> = {
    field: "isOnline",
    headerName: "Status",
    type: "string",
    minWidth: 100,
    editable: false,
    valueFormatter: ({ value }) => value ? "Connected" : "Awaiting response",
  }

  export const State: GridColDef<Sensor> = {
    field: "state",
    headerName: "Status",
    type: "string",
    minWidth: 60,
    editable: false,
    valueGetter: ({ row }) => row.state?.name || '-',
  }

  export const DateAdded: GridColDef<Sensor> = {
    field: "currentData.createdAt",
    headerName: "Date Added",
    type: "string",
    minWidth: 110,
    editable: false,
    valueGetter: ({ row }) => row.currentData?.createdAt || null,
    valueFormatter: ({ value }) => value ? format(new Date(value), "P") : "-",
  }

  export const Description: GridColDef<Sensor> = {
    field: "description",
    headerName: "Description",
    type: "string",
    minWidth: 180,
    editable: false,
  }

  export const DateAssigned: GridColDef<Sensor> = {
    field: "contractorSensors.0.createdAt",
    headerName: "Date Assigned",
    type: "string",
    minWidth: 180,
    editable: false,
    valueGetter: ({ row }) => row.contractorSensors && row.contractorSensors.length ? row.contractorSensors[0].createdAt : null,
    valueFormatter: ({ value }) => value ? format(new Date(value), "P") : "-",
  }

  export const IMEI: GridColDef<Sensor> = {
    field: "imei",
    headerName: "IMEI",
    type: "string",
    minWidth: 150,
    editable: false,
  }

  export const DevEUI: GridColDef<Sensor> = {
    field: "devEUI",
    headerName: "Dev EUI",
    type: "string",
    minWidth: 100,
    editable: false,
  }

  export const Contractor: GridColDef<Sensor> = {
    field: "contractorSensors.0.contractor.name",
    valueGetter: ({row}) => row.contractorSensors && row.contractorSensors.length ? row.contractorSensors[0].contractor?.name : null,
    headerName: "Contractor",
    type: "string",
    renderCell: ({ row }) => {
      const { contractorSensors } = row
      return (
        <>
          {contractorSensors?.length
            ? contractorSensors.map((el, idx, arr) => (
              <Fragment key={el.id}>
                <InnerLink to={`/contractors/${el.contractorId}`}>
                  {el?.contractor?.name}
                </InnerLink>
                {idx < arr.length - 1 && <br />}
              </Fragment>
            ))
            : "-"}
        </>
      )
    },
  }

  export const Project: GridColDef<Sensor> = {
    field: "projectSectionsSensors.0.project.name",
    valueGetter: ({row}) => row.projectSectionsSensors && row.projectSectionsSensors.length ? row.projectSectionsSensors[0].project?.name : null,
    headerName: "Project",
    type: "string",
    minWidth: 150,
    editable: false,
    renderCell: ({ row }) => {
      if (row?.projectSectionsSensors?.length) {
        const activeProjectSectionSensor = row.projectSectionsSensors.find((pss) => pss.isActive)
        if (!activeProjectSectionSensor) return null

        const projectId = activeProjectSectionSensor.projectId
        const projectName = activeProjectSectionSensor.project?.name || "-"
        return <InnerLink to={`/projects/${projectId}`}>{projectName}</InnerLink>
      }
      return null
    }
  }

  export const ProjectSection: GridColDef<Sensor> = {
    field: "projectSectionsSensors.0.projectSection.name",
    valueGetter: ({row}) => row.projectSectionsSensors && row.projectSectionsSensors.length ? row.projectSectionsSensors[0].projectSection?.name : null,
    headerName: "Project Section",
    type: "string",
    minWidth: 150,
    editable: false,
  }

  export const DateConnected: GridColDef<Sensor> = {
    field: "projectSectionsSensors.0.createdAt",
    headerName: "Date Connected",
    type: "string",
    minWidth: 110,
    editable: false,
    valueGetter: ({ row }) => row.projectSectionsSensors && row.projectSectionsSensors.length ? row.projectSectionsSensors[0].createdAt : null,
    valueFormatter: ({ value }) => value ? format(new Date(value), "P") : "-",
  }

  export const TimeOnProject: GridColDef<Sensor> = {
    field: "timeOnProject",
    headerName: "Time on a project",
    type: "string",
    minWidth: 110,
    editable: false,
    valueGetter: ({ row }) => row.projectSectionsSensors && row.projectSectionsSensors.length ? row.projectSectionsSensors[0].createdAt : null,
    valueFormatter: ({ value }) => {
      let preparedValue = "-"
      if (value) {
        const diff = Date.now() - new Date(value).getTime()
        const count = Math.round(diff / 60 / 60 / 24 / 1000)
        preparedValue = `${count} d`
      }
      return preparedValue
    },
  }

  export const UsedAt: GridColDef<Sensor> = {
    field: "projectSectionsSensors.0.project.fullAddress",
    headerName: "Used at",
    type: "string",
    minWidth: 110,
    editable: false,
    valueGetter: ({ row }) => row.projectSectionsSensors && row.projectSectionsSensors.length ? row.projectSectionsSensors[0].project?.fullAddress : null,
    valueFormatter: ({ value }) => value || "-",
  }

  export const City: GridColDef<Sensor> = {
    field: "projectSectionsSensors.0.project.city",
    headerName: "City",
    type: "string",
    minWidth: 110,
    editable: false,
    valueGetter: ({ row }) => row.projectSectionsSensors && row.projectSectionsSensors.length ? row.projectSectionsSensors[0].project?.city : null,
    valueFormatter: ({ value }) => value || "-",
  }

  export const ServicePlan: GridColDef<Sensor> = {
    field: "thingspaceSensor.servicePlan",
    headerName: "Service plan",
    type: "string",
    minWidth: 110,
    editable: false,
    valueGetter: ({ row }) => row.thingspaceSensor?.servicePlan || null,
  }

  export const AvailableInThingspace: GridColDef<Sensor> = {
    field: "thingspaceSensor",
    headerName: "Available in ThingSpace",
    type: "string",
    minWidth: 130,
    editable: false,
    renderCell: ({ row }) => {
      const availableInThingSpace = Boolean(row.thingspaceSensor)
      if (!availableInThingSpace) {
        return (
          <Grid width="100%" height="100%" container alignItems="center" borderRadius={1}>
            <Grid item pl={1}>
              <Typography variant="body2" color="#ff5c64">Not available</Typography>
            </Grid>
          </Grid>
        )
      }
      return (
        <Grid width="100%" height="100%" container alignItems="center" borderRadius={1}>
          <Grid item pl={1}>
            <Typography variant="body2">Available</Typography>
          </Grid>
        </Grid>
      )
    }
  }

  export const TechnicalStatus: GridColDef<Sensor> = {
    field: "technicalStatus",
    headerName: "Status",
    type: "string",
    minWidth: 180,
    editable: false,
    valueGetter: ({ row }) => {
      const { technicalStatus, statusUpdatedAt } = row
      const lastUpdated = statusUpdatedAt ? distanceInWords(new Date(statusUpdatedAt), new Date()) : null
      let status: string | null = ''
      if (technicalStatus === SensorTechnicalStatusEnum.Failed) status = 'Failed'
      else if (technicalStatus === SensorTechnicalStatusEnum.NotBad) status = 'Not Bad'
      else if (technicalStatus === SensorTechnicalStatusEnum.Offline) status = 'Offline'
      else if (technicalStatus === SensorTechnicalStatusEnum.Ok) status = 'OK'
      else if (technicalStatus === SensorTechnicalStatusEnum.Unstable) status = 'Unstable'
      else status = null

      return lastUpdated && status ? `${status} (${lastUpdated})` : (status || '-')
    },
  }

  export const Comment: GridColDef<Sensor> = {
    field: "comment",
    headerName: "Internal comment",
    type: "string",
    minWidth: 180,
    editable: true,
  }

  export const IsActive: GridColDef<Sensor> = {
    field: "projectSectionsSensors.0.isActive",
    headerName: "Is active",
    type: "string",
    minWidth: 50,
    editable: false,
    valueGetter: ({ row }) => row.projectSectionsSensors && row.projectSectionsSensors.length ? row.projectSectionsSensors[0].isActive : null,
    valueFormatter: ({ value }) => {
      if (value === true) return 'Yes'
      if (value === false) return 'No'
      return '-'
    },
  }

  export const DateAddedToProject: GridColDef<Sensor> = {
    field: "row.projectSectionsSensors.0.createdAt",
    headerName: "Date added",
    type: "string",
    minWidth: 110,
    editable: false,
    valueGetter: ({ row }) => (row.projectSectionsSensors?.length && row.projectSectionsSensors[0].createdAt) || null,
    valueFormatter: ({ value }) => value ? format(new Date(value), "P") : "-",
  }

  export const DateDisconnectedFromProject: GridColDef<Sensor> = {
    field: "row.projectSectionsSensors.0.disconnectTime",
    headerName: "Disconnected date",
    type: "string",
    minWidth: 110,
    editable: false,
    valueGetter: ({ row }) => (row.projectSectionsSensors?.length && row.projectSectionsSensors[0].disconnectTime) || null,
    valueFormatter: ({ value }) => value ? format(new Date(value), "P") : "-",
  }

  export const EnabledBluetooth: GridColDef<Sensor> = {
    field: "isEnabledBluetooth",
    headerName: "Bluetooth",
    type: "string",
    minWidth: 50,
    editable: false,
    valueGetter: ({ row }) => {
      const { isEnabledBluetooth } = row
      if (isEnabledBluetooth === true) return 'Enabled'
      if (isEnabledBluetooth === false) return 'Disabled'
      return '-'
    },
  }
}

export namespace UnstableSensorsColumns {
  export const DateTime: GridColDef<UnstableSensor> = {
    field: "date",
    headerName: "Date",
    type: "string",
    minWidth: 150,
    editable: false,
    valueFormatter: ({ value }) => value ? format(new Date(value), "P") : "-",
  }

  export const IMEI: GridColDef<UnstableSensor> = {
    field: "sensorImei",
    headerName: "IMEI",
    type: 'string',
    minWidth: 200,
    editable: false,
  }

  export const NumberMissedUplinks: GridColDef<UnstableSensor> = {
    field: "numberMissedUplinks",
    headerName: "Amount of missed uplinks",
    type: 'string',
    minWidth: 150,
    editable: false,
  }
}

export namespace SensorFirmwareHistory {
  export const Firmware: GridColDef<FirmwareHistoryItem> = {
    field: "firmware",
    headerName: "Firmware",
    type: "string",
    minWidth: 150,
    editable: false,
  }

  export const DateRange: GridColDef<FirmwareHistoryItem> = {
    field: "createdAt",
    headerName: "Date Range",
    type: "string",
    minWidth: 200,
    editable: false,
    valueGetter: ({ row }) => {
      if (row.createdAt && row.endAt) {
        const start = format(new Date(row.createdAt), 'P')
        const end = format(new Date(row.endAt), 'P')
        return `${start} - ${end}`
      }
      if (row.createdAt && !row.endAt) {
        const start = format(new Date(row.createdAt), 'P')
        return `${start} - NOW`
      }
      return null
    }
  }
}

export namespace SensorBackupUplinkColumns {
  export const LastModifiedAt: GridColDef<SensorBackupUplinkItem> = {
    field: "datetime",
    headerName: "Date",
    type: "string",
    minWidth: 220,
    editable: false,
    valueFormatter: ({ value }: GridValueFormatterParams<string>): string => value ? format(new Date(value), "PPpp") : "-",
  }

  export const FileName: GridColDef<SensorBackupUplinkItem> = {
    field: "fileName",
    headerName: "File name",
    type: "string",
    minWidth: 450,
    editable: false,
    renderCell: ({ value, row }: GridCellParams<SensorBackupUplinkItem, string>) => {
      const link = `/sensor-raw-uplink?link=${encodeURIComponent(row.link)}&fileName=${value}`
      return (
        <OuterLink to={link} target="_blank" rel="noreferrer">{value}</OuterLink>
      )
    }
  }

  export const Size: GridColDef<SensorBackupUplinkItem> = {
    field: "size",
    headerName: "Size",
    type: "string",
    minWidth: 150,
    editable: false,
    valueFormatter: ({ value }: GridValueFormatterParams<number>): string => value ? `${value} bytes` : "-",
  }
}

export default SensorColumns
