import { FC } from "react"
import { AdminMobileSettings } from "src/api"
import * as Yup from "yup"
import {
  Button,
  Grid,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material"
import { useFormik, FormikProvider, Form } from 'formik'
import { TextField } from "src/components/ui"

interface MobileSettingsProps {
    mobileSettings: AdminMobileSettings;
    onSubmit: (formState: AdminMobileSettings) => void;
    isLoading: boolean;
    description?: string;
}

const validationSchema = Yup.object({
  wagnerProbeSettings: Yup.object().shape({
    signalStrengthThreshold: Yup.number(),
    connectivitySignalLevel: Yup.number(),
    connectivityCheckPacketFreshnessThreshold: Yup.number(),
  }).nullable(),
})

export interface MobileSettingsEditState extends AdminMobileSettings { }

const defaultInitial: MobileSettingsEditState = {
  wagnerProbeSettings: {
    signalStrengthThreshold: 0,
    connectivitySignalLevel: 0,
    connectivityCheckPacketFreshnessThreshold: 0
  },
}

export const MobileSettingsEdit: FC<MobileSettingsProps> = ({ mobileSettings, onSubmit, isLoading, description }) => {

  const initialValues: MobileSettingsEditState = mobileSettings ? {
    wagnerProbeSettings: {
      signalStrengthThreshold: mobileSettings?.wagnerProbeSettings.signalStrengthThreshold,
      connectivitySignalLevel: mobileSettings?.wagnerProbeSettings.connectivitySignalLevel,
      connectivityCheckPacketFreshnessThreshold: mobileSettings?.wagnerProbeSettings.connectivityCheckPacketFreshnessThreshold,
    },
  } : defaultInitial

  const formik = useFormik({
    initialValues: { ...defaultInitial, ...initialValues },
    onSubmit: (data) => {
      for (const rootValue of Object.values(data)) {
        if (typeof rootValue !== 'object') continue
        for (const [childKey, childValue] of Object.entries(rootValue)) {
          if (childValue === '') {
            Object.assign(rootValue, { [childKey]: null })
          };
        }
      }
      onSubmit(data)
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  })

  return (
    <FormikProvider value={formik}>
      <Form>
        <Grid container spacing={1}>
          <Grid item>
            <Typography variant="body2" sx={{ mb: 2 }}>{description}</Typography>
          </Grid>
          <Grid item container>
            <Typography variant="h5" sx={{ mb: 2 }}>Wagner Probe Settings</Typography>
            <Grid item xs={12}>
              <TextField name="wagnerProbeSettings.signalStrengthThreshold" label="Signal strength threshold, dBm" TextFieldProps={{
                type: "number"
              }} />
            </Grid>
            <Grid item xs={12}>
              <TextField name="wagnerProbeSettings.connectivitySignalLevel" label="Connectivity signal level, dBm" TextFieldProps={{
                type: "number"
              }} />
            </Grid>
            <Grid item xs={12}>
              <TextField name="wagnerProbeSettings.connectivityCheckPacketFreshnessThreshold" label="Connectivity check packet freshness threshold, sec" TextFieldProps={{
                type: "number"
              }} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box pt={2}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading}
              >
                {
                  isLoading ? <CircularProgress color="info" size={26} /> : 'Save'
                }
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
}
